import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { HSStatFormat } from "types/enums";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";

import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSStat } from "components/charts/HSStat";


type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityStaffExpensesStat: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  
  const {   getLookupDescription } = useContext(LookupsContext);
  const [ statVal, setStatVal ] = useState<number>(0);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    
    
  let totalStaffExpenses: number = 0.0;
  
  
  if (dta != null)
  {
    for(const acc of dta)
    {
      
      totalStaffExpenses = totalStaffExpenses + acc?.staffExpenses;
    }
  }
    setStatVal(totalStaffExpenses);
  }, [dta, isLoading]);


  





  return <HSStat reportCode="ACTIVITYSTAFFEXPENSES" val={statVal} isLoading={isLoading} hsFormat={ HSStatFormat.HSCurrency} /> 
};
