import { Authenticated, AuthBindings, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, Layout, RefineSnackbarProvider, notificationProvider, LightTheme } from "@refinedev/mui";
import { CssBaseline, GlobalStyles, ThemeProvider, Backdrop, CircularProgress } from "@mui/material";
import routerBindings, { CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { MainLayout } from "layouts/mainLayout";
import { Header, Footer } from "./components";
import { Login } from "pages/login";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import axios, { AxiosRequestConfig } from "axios";
import { axiosInstance } from "index";
import { msalConfig, loginRequest, tokenRequest, msGraphTokenRequest } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import LoginTimeoutProvider from "contexts/loginTimeoutContext";
import LoggedInUserProvider from "contexts/loggedInUserContext";
import LookupsProvider from "contexts/lookupsContext";
import GranteeSelectionProvider from "contexts/granteeSelectionContext";
import { ServiceRequestListPage, ServiceRequestCreatePage, ServiceRequestEditPage, ServiceRequestShowPage } from "pages/service-requests";
import { ActivityListPage, ActivityCreatePage, ActivityEditPage, ActivityShowPage } from "pages/activities";
import { StaffListPage, StaffEditPage } from "pages/staff";
import { DashboardListPage } from "pages/dashboard";
import { ActivityReportPage } from "pages/reports/activityReportPage";
import { useEffect, useState } from "react";
import { ServiceRequestReportPage } from "pages/reports/serviceRequestReportPage";
import { ActivityAttendeesReportPage } from "pages/reports/activityAttendeesReportPage";
import { ErrorPage } from "pages/error/index";
import { EvaluationsReportPage } from "pages/reports/evaluationsReportPage";

import { QueryClient, QueryClientProvider, setLogger } from "react-query";
import { LogoutPrompt } from "pages/login/logoutPrompt";

import { HSLocalStorage } from "types/enums";

const queryClient = new QueryClient();

const customizedLightTheme = {
  ...LightTheme,
  palette: {
    ...LightTheme.palette,
    background: {
      default: "#f3f6f9",
    },
    primary: {
      main: "#4c5f90",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#4c5f90",
    },
  },
};

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      html: { WebkitFontSmoothing: "auto" },
    }}
  />
);

export const AUTH_TOKEN_KEY = "msal-auth-token";
export const AUTH_LOGGED_IN_USER_ID_KEY = "msal-auth-logged-in-user-id";
export const API_URL = `${process.env.REACT_APP_API_URL}`;
export const API_GRAPH_URL = `${process.env.REACT_APP_MS_GRAPH_API_URL}`;

const DEFAULT_TIMEOUT_MS = Number(`${process.env.REACT_APP_LOGIN_TIMEOUT}`);
const DEFAULT_TIMEOUTPROMPT_MS = Number(`${process.env.REACT_APP_LOGIN_TIMEOUT_PROMPT_BEFORE}`);

export default function App() {
  const { instance, inProgress, accounts } = useMsal();
  const [showLogoutPrompt, setShowLogoutPrompt] = useState<boolean>(false);

  const msalLogoutski = async () => {
    console.log("logging out...");

    let loggedInSptacer = instance.getAccountByLocalId(localStorage.getItem(AUTH_LOGGED_IN_USER_ID_KEY) ?? "");




    
    if (instance?.getAllAccounts().length > 0 || loggedInSptacer != null) {
      

      if (loggedInSptacer == null) {
      loggedInSptacer = instance.getAccountByLocalId(instance?.getAllAccounts()[0]?.localAccountId);
      }
      await instance.logoutRedirect({
        account: loggedInSptacer,
        logoutHint: loggedInSptacer?.idTokenClaims?.login_hint,
        onRedirectNavigate: (url) => {
          // Return false if you would like to stop navigation after local logout

          window.location.href = `${process.env.REACT_APP_AZURE_AAD_REDIRECT_URI}`;
          return true;
        },
        postLogoutRedirectUri: "/login",
      });
      localStorage.setItem(AUTH_TOKEN_KEY, "");
      localStorage.setItem(AUTH_LOGGED_IN_USER_ID_KEY, "");
      return {
        success: true,
      };
    
    }  else {
      if (window.location.href !== `${process.env.REACT_APP_AZURE_AAD_REDIRECT_URI}`) {
        window.location.href = `${process.env.REACT_APP_AZURE_AAD_REDIRECT_URI}`;
      }
    }
  };

  useEffect(() => {


    const tmpLoggedSptacer = instance.getAccountByLocalId(localStorage.getItem(AUTH_LOGGED_IN_USER_ID_KEY) ?? "");





    

    const updateLastActivity = () => {
      localStorage.setItem(HSLocalStorage.HSUSERACTIVITY, new Date().getTime().toString());
    };

    const getLastActivity = (): number => {
      return parseInt(localStorage.getItem(HSLocalStorage.HSUSERACTIVITY) ?? "");
    };

    const getIdleMilleseconds = () => {
      return new Date().getTime() - getLastActivity();
    };

    const startLogoutPrompt = () => {
      if (getIdleMilleseconds() > DEFAULT_TIMEOUT_MS - DEFAULT_TIMEOUTPROMPT_MS) {
        if (instance?.getAllAccounts().length > 0) {
          setShowLogoutPrompt(true);
        }
      }
    };

    const startLogout = () => {
      if (getIdleMilleseconds() > DEFAULT_TIMEOUT_MS) {
        msalLogoutski();
      } else {
        resetTimer(true);
      }
    };

    let logoutTimer = setTimeout(startLogout, DEFAULT_TIMEOUT_MS); // 30 minutes
    let promptTimer = setTimeout(startLogoutPrompt, DEFAULT_TIMEOUT_MS - DEFAULT_TIMEOUTPROMPT_MS);

    const resetTimer = (useIdleTimer?: boolean) => {
      //console.log('************* timer reset');

      let finalTimerMS = DEFAULT_TIMEOUT_MS;
      if (useIdleTimer != null && useIdleTimer === true) {
        finalTimerMS = DEFAULT_TIMEOUT_MS - getIdleMilleseconds();
      }

      clearTimeout(logoutTimer);
      clearTimeout(promptTimer);
      logoutTimer = setTimeout(startLogout, finalTimerMS); // 30 minutes
      promptTimer = setTimeout(startLogoutPrompt, finalTimerMS - DEFAULT_TIMEOUTPROMPT_MS);
      setShowLogoutPrompt(false);

      if (useIdleTimer == null || useIdleTimer === false) {
        updateLastActivity();
      }
    };

    const events = ["click"];
    events.forEach((event) =>
      window.addEventListener(event, (event) => {
        resetTimer(false);
      })
    );
    startLogout();
    return () => {
      clearTimeout(logoutTimer);
      clearTimeout(promptTimer);
      events.forEach((event) =>
        window.removeEventListener(event, (event) => {
          resetTimer(false);
        })
      );
    };
  }, []);

  if (inProgress === "login" || inProgress === "handleRedirect") {
    return (
      <Backdrop sx={{ background: "transparent", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  const account: AccountInfo = accounts[0];

  const request: SilentRequest = {
    ...tokenRequest,
    account,
  };

  const authProvider: AuthBindings = {
    login: async () => {
      const loginResult = await instance.loginPopup({ scopes: loginRequest.scopes, prompt: "select_account" });

      return {
        success: true,
      };
    },
    register: async () => ({
      success: true,
    }),
    updatePassword: async () => ({
      success: true,
    }),
    logout: async () => {
      /* const loggedInSptacer = instance.getAccountByLocalId(instance?.getAllAccounts()[0]?.localAccountId);

      await instance.logoutRedirect({
        account: loggedInSptacer,
        logoutHint: loggedInSptacer?.idTokenClaims?.login_hint,
        onRedirectNavigate: (url) => {
          // Return false if you would like to stop navigation after local logout

          window.location.href = `${process.env.REACT_APP_AZURE_AAD_REDIRECT_URI}`;
          return true;
        },
        postLogoutRedirectUri: "/login",
      });
      localStorage.setItem(AUTH_TOKEN_KEY, "");
      localStorage.setItem(AUTH_LOGGED_IN_USER_ID_KEY, "");
      return {
        success: true,
      }; */





      await msalLogoutski();
      return {
        success: true,
      };
    },
    check: async () => {
      try {
        if (account) {
          
          const token = await instance.acquireTokenSilent(request);

          localStorage.setItem(AUTH_TOKEN_KEY, token.accessToken);
          return {
            authenticated: true,
          };
        } else {
          return {
            authenticated: false,
            redirectTo: "/login",
          };
        }
      } catch (e) {
        return {
          authenticated: false,
          redirectTo: "/login",
        };
      }
    },
    onError: async (error) => {
      return {
        redirectTo: "/error", // NOTE: "/error" is not configured as a route; making this fallout to "*" i.e. any non-routed path will go to the "*" error catchall. Once path="/error/index/:id?" is implemented, this can be changed to that path.
      };
    },
    getPermissions: async () => {
      if (account === null || account === undefined) {
        return [];
      }
      //console.log("getPermissions: account: ", account);
      return account?.idTokenClaims?.roles ?? [];
    },
    getIdentity: async (): Promise<AccountInfo | null> => {
      if (account === null || account === undefined) {
        return null;
      }
      return account;
    },
  };

  return (
    <ThemeProvider theme={customizedLightTheme}>
      <BrowserRouter>
        <RefineKbarProvider>
          <CssBaseline />
          {inputGlobalStyles}
          <RefineSnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <Refine
                dataProvider={dataProvider(API_URL, axiosInstance)}
                /* notificationProvider={notificationProvider} */
                routerProvider={routerBindings}
                authProvider={authProvider}
                resources={[
                  {
                    name: "dashboard",
                    list: "/dashboard",
                    meta: {
                      label: "Dashboard",
                    },
                  },
                  {
                    name: "servicerequests",
                    list: "/servicerequests",
                    create: "/servicerequests/create",
                    edit: "/servicerequests/edit/:id",
                    show: "/servicerequests/read/:id",
                    meta: {
                      label: "Service Requests",
                    },
                  },
                  {
                    name: "activities",
                    list: "/activities",
                    create: "/activities/create/:id",
                    edit: "/activities/edit/:id",

                    show: "/activities/read/:id",
                    meta: {
                      label: "T/TA Activities",
                    },
                  },
                  {
                    name: "staff",
                    list: "/staff",
                    edit: "/staff/edit/:userId",
                    meta: {
                      label: "Staff",
                    },
                  },

                  {
                    name: "reports",
                    list: "/reports",
                    meta: {
                      label: "Reports",
                    },
                  },
                ]}
                options={{
                  disableTelemetry: true,
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                }}
              >
                <LogoutPrompt showLogoutPrompt={showLogoutPrompt} />
                <Routes>
                  <Route
                    element={
                      <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                        {/* <LoginTimeoutProvider logout={msalLogout}> */}
                        <LoggedInUserProvider>
                          <MainLayout>
                            <LookupsProvider>
                              <GranteeSelectionProvider>
                                <Outlet />
                              </GranteeSelectionProvider>
                            </LookupsProvider>
                          </MainLayout>
                        </LoggedInUserProvider>
                        {/* </LoginTimeoutProvider> */}
                      </Authenticated>
                    }
                  >
                    <Route index element={<NavigateToResource resource="dashboard" />} />
                    <Route path="/dashboard">
                      <Route index element={<DashboardListPage />} />
                    </Route>
                    <Route path="/servicerequests">
                      <Route index element={<ServiceRequestListPage />} />
                      <Route path="create" element={<ServiceRequestCreatePage />} />
                      <Route path="edit/:id" element={<ServiceRequestEditPage />} />
                      <Route path="read/:id" element={<ServiceRequestShowPage />} />
                    </Route>
                    <Route path="/activities">
                      <Route index element={<ActivityListPage />} />
                      <Route path="create/:id" element={<ActivityCreatePage />} />
                      <Route path="edit/:id" element={<ActivityEditPage />} />
                      <Route path="read/:id" element={<ActivityShowPage />} />
                      <Route path="evaluations/:id" element={<EvaluationsReportPage />} />
                    </Route>
                    <Route path="/staff">
                      <Route index element={<StaffListPage />} />
                      <Route path="edit/:userId" element={<StaffEditPage />} />
                    </Route>
                    <Route path="/reports">
                      <Route index element={<ServiceRequestReportPage />} />
                      <Route path="activityReport" element={<ActivityReportPage />} />
                      <Route path="activityattendeesreport" element={<ActivityAttendeesReportPage />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <Authenticated fallback={<Outlet />}>
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                        {/* <LoginTimeoutProvider logout={msalLogout}>
                        <LookupsProvider>
                          <Layout Sider={() => null} Header={Header} Footer={Footer}>
                            <Outlet />
                          </Layout>
                        </LookupsProvider>
                      </LoginTimeoutProvider> */}
                        <MainLayout>
                          <Outlet />
                        </MainLayout>
                      </Authenticated>
                    }
                  >
                    <Route path="/error/index/:id?" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
              </Refine>
            </QueryClientProvider>
          </RefineSnackbarProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
