import qs from "qs";

export function getParamAsString(param: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined): string {
  let str: string = "";

  if (Array.isArray(param)) {
    str = getParamAsStringArray(param).join(",");
  } else if (typeof param === "string") {
    str = param;
  } else if (param instanceof Object) {
    const parsedParams = Array.isArray(param) ? param : [param];
    str = parsedParams
      .map((p) => getStringFromParam(p))
      .flat()
      .join(",");
  }

  return str;
}

export function getParamAsStringArray(param: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined): string[] {
  let arr: string[] = [];

  if (Array.isArray(param)) {
    arr = param.map((p) => getStringFromParam(p)).flat();
  } else if (typeof param === "string") {
    arr = JSON.parse(param) as string[];
  } else if (param instanceof Object) {
    const parsedParams = Array.isArray(param) ? param : [param];
    arr = parsedParams.map((p) => getStringFromParam(p)).flat();
  }

  return arr;
}

function getStringFromParam(param: string | qs.ParsedQs): string[] {
  let str: string[] = [];

  if (typeof param === "string") {
    str = [param];
  } else if (typeof param === "object") {
    const values = Object.values(param);
    str = values.filter((v): v is string => typeof v === "string");
  }

  return str;
}
