import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { RecordStatus } from "types/enums";
import { BaseRecord } from "@refinedev/core";

interface ExpenseSummaryTotals {
  categoryCode: string;
  estimatedTotal?: number;
  actualTotal: number;

  displayOrder: number;
}

interface HourlyExpenseTotals {
  [key: string]: { estimatedHours: number; actualHours: number; estimatedTotal: number; actualTotal: number };
}

interface HourlyStaffExpenseViewProps {
  data: BaseRecord | undefined;
  activityData?: any[] | undefined;
  isActivityView?: boolean | undefined;
}

export const ExpenseSummary: React.FC<HourlyStaffExpenseViewProps> = ({ data, activityData, isActivityView }) => {
  const [summaryTotals, setSummaryTotals] = useState<ExpenseSummaryTotals[]>([]);
  const [consultantEstimatedSubTotal, setConsultantEstimatedSubTotal] = useState<number>(0);

  const [consultantActualSubTotal, setConsultantActualSubTotal] = useState<number>(0);
  const [totalEstimatedExpense, setTotalEstimatedExpense] = useState<number>(0);
  const [totalActualExpense, setTotalActualExpese] = useState<number>(0);

  const { userPermissions } = useContext(LoggedInUserContext);
  const { getLookupDescription } = useContext(LookupsContext);

  useEffect(() => {
    console.log("here20", data);
    if (data != null) setExpenseTotals(data);
  }, [data]);

  const setExpenseTotals = (dta: BaseRecord | undefined) => {
    // Hourly Expenses

    let tmpSummaryTotals: ExpenseSummaryTotals[] = [];

    dta?.hourlyExpenses?.forEach((expense: any) => {
      let acc: any = null;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
      }

      if (
        (acc != null &&
          ((acc.approvalWorkflowState != null && acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) ||
            isActivityView === true)) ||
        acc == null
      ) {
        if (expense.isConsultant === true) {
          console.log("here10", expense);

          let consultantIndex: number = tmpSummaryTotals.findIndex((exs: any) => exs.categoryCode === "CONSULTANTS");

          if (consultantIndex > -1) {
            tmpSummaryTotals[consultantIndex].estimatedTotal = tmpSummaryTotals[consultantIndex].estimatedTotal + expense.estimatedTotal;
            tmpSummaryTotals[consultantIndex].actualTotal = tmpSummaryTotals[consultantIndex].actualTotal + expense.actualTotal;
          } else {
            tmpSummaryTotals.push({ categoryCode: "CONSULTANTS", estimatedTotal: expense.estimatedTotal, actualTotal: expense.actualTotal, displayOrder: 20 });
          }
        } else {
          let staffIndex: number = tmpSummaryTotals.findIndex((exs: any) => exs.categoryCode === "STAFFLABOR");

          if (staffIndex > -1) {
            tmpSummaryTotals[staffIndex].estimatedTotal = tmpSummaryTotals[staffIndex].estimatedTotal + expense.estimatedTotal;
            tmpSummaryTotals[staffIndex].actualTotal = tmpSummaryTotals[staffIndex].actualTotal + expense.actualTotal;
          } else {
            tmpSummaryTotals.push({ categoryCode: "STAFFLABOR", estimatedTotal: expense.estimatedTotal, actualTotal: expense.actualTotal, displayOrder: 60 });
          }
        }
      }
    });
    if (tmpSummaryTotals?.find((y) => y.categoryCode === "CONSULTANTS") == null) {
      tmpSummaryTotals.push({ categoryCode: "CONSULTANTS", estimatedTotal: 0, actualTotal: 0, displayOrder: 20 });
    }

    if (tmpSummaryTotals?.find((y) => y.categoryCode === "STAFFLABOR") == null) {
      tmpSummaryTotals.push({ categoryCode: "STAFFLABOR", estimatedTotal: 0, actualTotal: 0, displayOrder: 60 });
    }

    dta?.dailyExpenses?.forEach((expense: any) => {
      let acc: any = null;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
      }

      if (
        (acc != null &&
          ((acc.approvalWorkflowState != null && acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) ||
            isActivityView === true)) ||
        acc == null
      ) {
        let staffIndex: number = tmpSummaryTotals.findIndex((exs: any) => exs.categoryCode === "TRAVEL");

        if (staffIndex > -1) {
          tmpSummaryTotals[staffIndex].estimatedTotal = tmpSummaryTotals[staffIndex].estimatedTotal + expense.estimatedTotal;
          tmpSummaryTotals[staffIndex].actualTotal = tmpSummaryTotals[staffIndex].actualTotal + expense.actualTotal;
        } else {
          tmpSummaryTotals.push({ categoryCode: "TRAVEL", estimatedTotal: expense.estimatedTotal, actualTotal: expense.actualTotal, displayOrder: 10 });
        }
      }
    });
    dta?.transportationExpenses?.forEach((expense: any) => {
      let acc: any = null;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
      }

      if (
        (acc != null &&
          ((acc.approvalWorkflowState != null && acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) ||
            isActivityView === true)) ||
        acc == null
      ) {
        let staffIndex: number = tmpSummaryTotals.findIndex((exs: any) => exs.categoryCode === "TRAVEL");

        if (staffIndex > -1) {
          tmpSummaryTotals[staffIndex].estimatedTotal = tmpSummaryTotals[staffIndex].estimatedTotal + expense.estimatedTotal;
          tmpSummaryTotals[staffIndex].actualTotal = tmpSummaryTotals[staffIndex].actualTotal + expense.actualTotal;
        } else {
          tmpSummaryTotals.push({ categoryCode: "TRAVEL", estimatedTotal: expense.estimatedTotal, actualTotal: expense.actualTotal, displayOrder: 10 });
        }
      }
    });
    dta?.mileageExpenses?.forEach((expense: any) => {
      let acc: any = null;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
      }

      if (
        (acc != null &&
          ((acc.approvalWorkflowState != null && acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) ||
            isActivityView === true)) ||
        acc == null
      ) {
        let staffIndex: number = tmpSummaryTotals.findIndex((exs: any) => exs.categoryCode === "TRAVEL");

        if (staffIndex > -1) {
          tmpSummaryTotals[staffIndex].estimatedTotal = tmpSummaryTotals[staffIndex].estimatedTotal + expense.estimatedTotal;
          tmpSummaryTotals[staffIndex].actualTotal = tmpSummaryTotals[staffIndex].actualTotal + expense.actualTotal;
        } else {
          tmpSummaryTotals.push({ categoryCode: "TRAVEL", estimatedTotal: expense.estimatedTotal, actualTotal: expense.actualTotal, displayOrder: 10 });
        }
      }
    });

    if (tmpSummaryTotals?.find((y) => y.categoryCode === "TRAVEL") == null) {
      tmpSummaryTotals.push({ categoryCode: "TRAVEL", estimatedTotal: 0, actualTotal: 0, displayOrder: 10 });
    }

    if (activityData != null && activityData.length > 0) {
      let tmpMaterialsCostTotal: number = 0;
      let tmpEstimatedMaterialsCostTotal: number = 0;
      activityData.forEach((acc) => {
        if (acc.approvalWorkflowState != null &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) {
          tmpMaterialsCostTotal = tmpMaterialsCostTotal + (acc?.materialsCost != null ? acc.materialsCost : 0);
          tmpEstimatedMaterialsCostTotal = tmpEstimatedMaterialsCostTotal + (acc?.estimatedMaterialsCost != null ? acc?.estimatedMaterialsCost : 0);
        }
      });
      tmpSummaryTotals.push({ categoryCode: "MATERIALS", estimatedTotal: tmpEstimatedMaterialsCostTotal, actualTotal: tmpMaterialsCostTotal, displayOrder: 20 });
    } else {
      tmpSummaryTotals.push({
        categoryCode: "MATERIALS",
        estimatedTotal: dta?.estimatedMaterialsCost != null ? dta.estimatedMaterialsCost : 0,
        actualTotal: dta?.materialsCost != null ? dta.materialsCost : 0,
        displayOrder: 20,
      });
    }

    if (tmpSummaryTotals?.find((y) => y.categoryCode === "MATERIALS") == null) {
      tmpSummaryTotals.push({ categoryCode: "MATERIALS", estimatedTotal: 0, actualTotal: 0, displayOrder: 20 });
    }

    let tmpConsultantEstimatedSubTotal: number = 0;
    let tmpConsultantActualSubTotal: number = 0;

    let tmpTotalEstimated: number = 0;
    let tmpTotalActual: number = 0;

    tmpSummaryTotals.forEach((expense) => {
      if (expense.categoryCode === "CONSULTANTS" || expense.categoryCode === "TRAVEL" || expense.categoryCode === "MATERIALS") {
        tmpConsultantEstimatedSubTotal = tmpConsultantEstimatedSubTotal + (expense.estimatedTotal != null ? expense.estimatedTotal : 0);

        tmpConsultantActualSubTotal = tmpConsultantActualSubTotal + expense.actualTotal;
      }
      setConsultantEstimatedSubTotal(tmpConsultantEstimatedSubTotal);
      setConsultantActualSubTotal(tmpConsultantActualSubTotal);

      tmpTotalEstimated = tmpTotalEstimated + (expense.estimatedTotal != null ? expense.estimatedTotal : 0);
      tmpTotalActual = tmpTotalActual + expense.actualTotal;
    });

    tmpSummaryTotals.push({ categoryCode: "CORSUBTOTAL", estimatedTotal: tmpConsultantEstimatedSubTotal, actualTotal: tmpConsultantActualSubTotal, displayOrder: 50 });

    setTotalEstimatedExpense(tmpTotalEstimated);
    setTotalActualExpese(tmpTotalActual);

    
    
    
    tmpSummaryTotals.sort((y, z) => y.displayOrder - z.displayOrder);

    setSummaryTotals(tmpSummaryTotals);
    //if (tmpStaffExpenses.find((itm) => itm.
  };

  return (
    <div className="tab-pane" id="expenses" role="tabpanel" aria-labelledby="tab-3">
      {summaryTotals !== null && summaryTotals.length > 0 && (
        <>
          <h3 className="h4 mb-3 py-2 text-bg-light">Cost Breakdown</h3>

          <table className="table hs-table-xs">
            <thead>
              <tr className="fw-bold">
                <th scope="col" className="w-20">
                  Type
                </th>

                <th scope="col" className="w-20">
                  Estimated Total
                </th>
                <th scope="col">Actual Total</th>
              </tr>
            </thead>
            <tbody>
              {summaryTotals.map((expense: any, index: any) => {
                let trClassName: string = "";
                if (expense.categoryCode === "CORSUBTOTAL") {
                  trClassName = "table-primary";
                }
                return (
                  <tr key={index} className={trClassName}>
                    <td>{getLookupDescription(expense.categoryCode)}</td>

                    <td>{expense.estimatedTotal != null && Number(expense.estimatedTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                    <td>{expense.actualTotal != null && Number(expense.actualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot className="table-footer-divider">
              <tr className="fw-bold">
                <th scope="row">Totals</th>
                <td>{Number(totalEstimatedExpense).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                <td>{Number(totalActualExpense).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
              </tr>
            </tfoot>
          </table>
        </>
      )}
    </div>
  );
};
