import { Configuration, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_AAD_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_AZURE_AAD_TENANT_NAME}`,
    redirectUri: `${process.env.REACT_APP_AZURE_AAD_REDIRECT_URI}`,
    //postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            //console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  sptacAPI: {
    endpoint: `${process.env.REACT_APP_API_URL}`,
    scopes: {
      read: [`${process.env.REACT_APP_AZURE_AAD_AUDIENCE_URI}Sptac.Read`],
      write: [`${process.env.REACT_APP_AZURE_AAD_AUDIENCE_URI}Sptac.ReadWrite`],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

export const loginRequest = {
  scopes: [...protectedResources.sptacAPI.scopes.read, ...protectedResources.sptacAPI.scopes.write],
};

export const tokenRequest = {
  scopes: [...protectedResources.sptacAPI.scopes.read, ...protectedResources.sptacAPI.scopes.write],
};

export const msGraphTokenRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"],
};
