import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOne, BaseRecord } from "@refinedev/core";
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { decryptData, encryptData } from "utils/crypto";
import { ActivityRead } from "components";

export const ActivityShowPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const params = useParams();
  const activityId: number = Number(decryptData(decodeURIComponent(params.id ?? "")));

  const [id, setId] = useState(activityId);
  const { data, isLoading, isError } = useOne({
    resource: "activities",
    id,
  });
  const activityData: BaseRecord = data?.data ?? [];

  
  useEffect(() => {
    if (activityData != null && isLoading === false) {
      setBreadCrumbsTitle("T/TA Activity");
      setBreads([
        {
          label: activityData?.serviceRequestTitle,
          path: `/servicerequests/read/${encodeURIComponent(encryptData(activityData?.serviceRequestId))}`,
        },
        { label: `${activityData?.title} (TA-${id})`, path: "" },
      ]);
    } 
  }, [activityData]);

  if (isLoading) {
    return (






      <>
            <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
        <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
        </div>
      </main>
      </>
);
  }

  if (isError) {
    return <div>Error: Could not load T/TA Activity data.</div>;
  }

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ActivityRead data={activityData} editUri={`/activities/edit/${encodeURIComponent(params.id ?? "")}`} />
        </div>
      </main>
    </>
  );
};
