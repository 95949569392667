import { BaseRecord } from "@refinedev/core";

export function aggregateGranteeAttendanceDataByRegion(data: BaseRecord[]): BaseRecord[] {
  const aggregatedData: { [key: string]: BaseRecord } = {};
  data?.forEach((event) => {
    if (aggregatedData[event.regionCode]) {
      aggregatedData[event.regionCode].numberOfAttendees += event.numberOfAttendees;
    } else {
      aggregatedData[event.regionCode] = {
        regionCode: event.regionCode,
        numberOfAttendees: event.numberOfAttendees,
      };
    }
  });
  return Object.values(aggregatedData);
}
