import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Collapse } from "@mui/material";
// import { GridColDef } from "@mui/x-data-grid";
// import dayjs from "dayjs";

//Local Components
// import CustomDataGrid from "components/datagrid";
// import { encryptData } from "utils/crypto";

import { ActivityFilterCriteria } from "components/form/activityFilterCriteria";
// import { LookupsContext } from "contexts/lookupsContext";
import { Control } from "react-hook-form";
import { FilterCriteria } from "types/filterCriteria";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";

// import { string } from "yargs";
import { ActivityRegionChart } from "components/charts/ActivityRegionChart";
import { ActivityTopicChart } from "components/charts/ActivityTopicChart";

import { ActivityPopulationChart } from "components/charts/ActivityPopulationChart";
import { ActivityFToFMethodsChart } from "components/charts/ActivityFToFMethodsChart";
import { ActivityVMethodsChart } from "components/charts/ActivityVMethods";
import { ActivityPrioritiesChart } from "components/charts/ActivityPrioritiesChart";
import { ActivitySPFFocusChart } from "components/charts/ActivitySPFFocusChart";
import { ActivityNumStat } from "components/charts/ActivityNumStat";
import { ActivityRecipientsStat } from "components/charts/ActivityRecipientsStat";
import { ActivityHoursStat } from "components/charts/ActivityHoursStat";
import { ActivityStaffExpensesStat } from "components/charts/ActivityStaffExpensesStat";

import { ActivityNonStaffExpensesStat } from "components/charts/ActivityNonStaffExpensesStat";
import { ActivityTotalExpensesStat } from "components/charts/ActivityTotalExpensesStat";
import { ActivityListChart } from "components/charts/ActivityListChart";
import { ActivityActualHoursStat } from "components/charts/ActivityActualHoursStat";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
type ActivityDashboardProps = {
  data: BaseRecord[];
  isLoading: boolean;
  isFilterExpanded?: boolean;

  isExpenseExpanded?: boolean;
  onFilterResults?: () => void;
};

export const DashboardList: React.FC<ActivityDashboardProps> = ({
  data,
  isLoading,
  isFilterExpanded,
  isExpenseExpanded,
  onFilterResults,
}) => {
  const [activityData, setActivityData] = useState<BaseRecord[]>([]);
  const [filteredDataForCharts, setFilteredActivityDataForCharts] = useState<BaseRecord[]>([]);
  const [filterForm, setFilterForm] = useState<Control>();
  const [dashboardIsLoading, setDashboardIsLoading] = useState<boolean>(true);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    setActivityData(data); //save fuller data set to state
    setFilteredActivityDataForCharts(data); //initially set to "full" data set
    setDashboardIsLoading(isLoading);






    setFilterExpanded(false);

setExpanded(false);    
  }, [data, isLoading]);





  useEffect(() => {









    if (isFilterExpanded != null) {
      setFilterExpanded(isFilterExpanded);
    }

  }, [isFilterExpanded]);





  useEffect(() => {









    if (isExpenseExpanded != null) {
      setExpanded(isExpenseExpanded);
    }


    
    
    
   
  }, [isExpenseExpanded]);

  const { userPermissions } = useContext(LoggedInUserContext);


  const filterResults = (filterCriteria: FilterCriteria) => {
    setDashboardIsLoading(true);
    setFilterForm(filterCriteria.filterForm);
    if (onFilterResults != null)
    {
      onFilterResults();
    }
    const getFilteredData = async () => {
      const response = await axiosInstance.get(`${API_URL}/activities`, {
        params: {
          $filter: filterCriteria.oDataFilter,
          // $orderby: "title",
          // $top: 10,
          // $skip: 0,
        },
      });

      //console.log("odata filtered response", response);

      setFilteredActivityDataForCharts(response?.data);

      



      setDashboardIsLoading(false);
      // Set last breadcrumb to be the filter desciption label
      if (filterCriteria.filterDescription) {
        setBreadcrumbs([
          { label: "Dashboard", path: "/dashboard" },
          { label: filterCriteria.filterDescription, path: "" },
        ]);
      } else {
        setBreadcrumbs([{ label: "Dashboard", path: "/dashboard" }]);
      }
    };
    getFilteredData();
    //console.log("here20", filteredDataForCharts);

    setFilterExpanded(false);
    window.scrollTo(0, 0);
  };

  const clearFilter = () => {
    setFilteredActivityDataForCharts(activityData);
    if (onFilterResults != null) {
      onFilterResults();
    }
    // Clear last breadcrumb to remove the filter desciption label
    setBreadcrumbs([{ label: "Dashboard", path: "/dashboard" }]);
    window.scrollTo(0, 0);
  };

  return (
    <div className="content flex-row-fluid" id="exp_content">
      <ActivityFilterCriteria
        filterResults={filterResults}
        clearFilter={clearFilter}
        isFilterExpanded={filterExpanded}
      />
      <div className="row gy-5 g-xl-10">
        <div className="col-md-3 mb-xl-10 mb-sm-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-solid fa-chalkboard-user fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityNumStat dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Activities</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-xl-10 mb-sm-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-solid fa-users fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityRecipientsStat
                    dta={filteredDataForCharts}
                    filterForm={filterForm}
                    isLoading={dashboardIsLoading}
                  />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Recipients</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-xl-10 mb-sm-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-solid fa-clock fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityHoursStat
                    dta={filteredDataForCharts}
                    filterForm={filterForm}
                    isLoading={dashboardIsLoading}
                  />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Staff Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-xl-10 mb-sm-5 mb-xs-5">
          <div className="card h-lg-100">
            <div className="card-body d-flex justify-content-between align-items-start flex-column">
              <div className="m-0">
                <i className="fa-regular fa-clock fs-1" style={{ color: "#4c5f90" }}></i>
              </div>

              <div className="d-flex flex-column my-7">
                <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                  <ActivityActualHoursStat
                    dta={filteredDataForCharts}
                    filterForm={filterForm}
                    isLoading={dashboardIsLoading}
                  />
                </span>

                <div className="m-0">
                  <span className="fw-semibold fs-6 text-gray-700">Delivery Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

{userPermissions.canViewStaffRates === true && 
(
      <Collapse in={expanded}>
        <div className="" id="collapseExpenses">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-semibold text-dark">Staff Rates & Expenses</span>
              </h2>
            </div>

            <div className="card-body py-10">
              <div className="row">
                <div className="col">
                  <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                    <span className="fs-4 fw-semibold text-dark pb-1 px-2">Staff Rates</span>
                    <span className="fs-lg-2x fw-semibold d-flex justify-content-center">
                      <ActivityStaffExpensesStat
                        dta={filteredDataForCharts}
                        filterForm={filterForm}
                        isLoading={dashboardIsLoading}
                      />
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                    <span className="fs-4 fw-semibold text-dark pb-1 px-2">Expenses</span>
                    <span className="fs-lg-2x fw-semibold d-flex justify-content-center">
                      <ActivityNonStaffExpensesStat
                        dta={filteredDataForCharts}
                        filterForm={filterForm}
                        isLoading={dashboardIsLoading}
                      />
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="card card-dashed flex-center min-w-175px my-3 p-6">
                    <span className="fs-4 fw-semibold text-dark pb-1 px-2">Total Expenses</span>
                    <span className="fs-lg-2x fw-semibold d-flex justify-content-center">
                      <ActivityTotalExpensesStat
                        dta={filteredDataForCharts}
                        filterForm={filterForm}
                        isLoading={dashboardIsLoading}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
)
}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Activities by Region</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityRegionChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Activities by Topic</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityTopicChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Activities by Population</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityPopulationChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-md-3">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Methods (Face)</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityFToFMethodsChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">T/TA Methods (Virtual)</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityVMethodsChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">Priorities</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityPrioritiesChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">SPF Focus Area</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivitySPFFocusChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-sm-12">
          <div className="card card-flush">
            <div className="card-header">
              <h2 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">Activities</span>
              </h2>
            </div>

            <div className="card-body pt-3 pb-5">
              <span>
                <ActivityListChart dta={filteredDataForCharts} filterForm={filterForm} isLoading={dashboardIsLoading} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
