import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import { HSStatFormat } from "types/enums";
import { LookupsContext } from "contexts/lookupsContext";
import { BreadcrumbContext } from "contexts/breadcrumbContext";


type HSStatParams = {
  reportCode: string;
  
  val: number;
  isLoading?: boolean;
  hsFormat?: HSStatFormat
};



export const HSStat: React.FC<HSStatParams> = ({reportCode, val, isLoading, hsFormat}) => {
  const { getLookupDescription, getLookupDescriptions } =    useContext(LookupsContext);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);

  
  useEffect(() => {
    
  }, []);





  if (isLoading === true)
  {

return (
<Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>

);

  }

let finalVal: string = "";

if (hsFormat != null)
{


  if (hsFormat === HSStatFormat.HSInteger)
  {
    finalVal = val.toString();
  }
  else if (hsFormat === HSStatFormat.HSFormattedInteger)
  {
    finalVal = val.toLocaleString('en-US');
  }
  else if (hsFormat === HSStatFormat.HSCurrency)
  {
    finalVal = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
  else if (hsFormat === HSStatFormat.HSDecimal)
  {
    finalVal = val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  else {
  finalVal =    val.toLocaleString('en-US');
  
}
}

  return (

    <span className="hsstat">{finalVal}</span>

  )
};
