import React, { useState, useEffect, useContext } from "react";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { ChartData } from "types/chartData";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type HSChartParams = {
  reportCode: string;
  chartData?: ChartData[];
  isLoading?: boolean;
};
require("highcharts/modules/series-label")(Highcharts);

export const HSHBarChart: React.FC<HSChartParams> = ({ chartData, isLoading }) => {
  const [chartResult, setChartResult] = useState<ChartData[]>([]);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    if (chartData != null) {
      setChartResult(chartData);
    }
  }, [chartData]);

  var pieData = [];
  let legendIndex = -1;

  let isJustRight = false;
  let categories = [];
  let sortedCategories = [];
  let sortedPieData: any[] = [];
  if (chartResult !== null) {
    for (var i = 0; i < chartResult.length; i++) {
      var colorCode = "";
      if (chartResult[i].desc === "Strongly Agree" || chartResult[i].desc === "Strongly agree") {
        colorCode = "#02a94f";
        legendIndex = 0;
      } else if (chartResult[i].desc === "Agree") {
        colorCode = "#a4d65e";
        legendIndex = 1;
      } else if (chartResult[i].desc === "Disagree") {
        colorCode = "#f19c22";
        legendIndex = 2;
      } else if (chartResult[i].desc === "Strongly Disagree" || chartResult[i].desc === "Strongly disagree") {
        colorCode = "#f26522";
        legendIndex = 3;
      } else if (chartResult[i].desc === "Just right") {
        colorCode = "#02a94f";
        legendIndex = 0;
        isJustRight = true;
      } else if (chartResult[i].desc === "A bit too advanced") {
        colorCode = "#af3dbe";
        legendIndex = 2;
        isJustRight = true;
      } else if (chartResult[i].desc === "A bit too simple") {
        colorCode = "#008ce3";
        legendIndex = 1;
        isJustRight = true;
      } else if (chartResult[i].desc === "Far too advanced") {
        colorCode = "#5f2167";
        legendIndex = 3;
        isJustRight = true;
      } else if (chartResult[i].desc === "Far too simple") {
        colorCode = "#00558c";
        legendIndex = 4;
        isJustRight = true;
      } else if (chartResult[i].desc === "Very dissatisfied") {
        colorCode = "#f26522";
        legendIndex = 3;
        isJustRight = true;
      } else if (chartResult[i].desc === "Somewhat dissatisfied") {
        colorCode = "#f19c22";
        legendIndex = 2;
        isJustRight = true;
      } else if (chartResult[i].desc === "Somewhat satisfied") {
        colorCode = "#a4d65e";
        legendIndex = 1;
        isJustRight = true;
      } else if (chartResult[i].desc === "Very satisfied" || chartResult[i].desc === "Very satisified") {
        colorCode = "#02a94f";
        legendIndex = 0;
        isJustRight = true;
      } else if (chartResult[i].desc === "Much more knowledgeable") {
        colorCode = "#02a94f";
        legendIndex = 0;
        isJustRight = true;
      } else if (chartResult[i].desc === "Somewhat more knowledgeable") {
        colorCode = "#a4d65e";
        legendIndex = 1;
        isJustRight = true;
      } else if (chartResult[i].desc === "A little more knowledgeable") {
        colorCode = "#f19c22";
        legendIndex = 2;
        isJustRight = true;
      } else if (chartResult[i].desc === "Very likely") {
        colorCode = "#02a94f";
        legendIndex = 0;
        isJustRight = true;
      } else if (chartResult[i].desc === "Somewhat likely") {
        colorCode = "#a4d65e";
        legendIndex = 1;
        isJustRight = true;
      } else if (chartResult[i].desc === "Not very likely") {
        colorCode = "#f19c22";
        legendIndex = 2;
        isJustRight = true;
      } else if (chartResult[i].desc === "Not at all likely") {
        colorCode = "#02a94f";
        legendIndex = 3;
        isJustRight = true;
      }

      if (chartResult[i].desc != null && chartResult[i].desc !== "null") {
        pieData.push({
          name: chartResult[i].desc,
          data: [chartResult[i].val],
          color: colorCode,
          legendIndex: legendIndex,
          events: {
            legendItemClick: function () {
              return false;
            },
            mouseOver: function () {
              return false;
            },
            mouseOut: function () {
              return false;
            },
          },
        });
      }
    }

    if (pieData != null) {
      sortedPieData = pieData.slice().sort((a, b) => b.legendIndex - a.legendIndex);
    }
  }

  if (sortedPieData != null) {
    for (var i = 0; i < sortedPieData.length; i++) {
      categories.push(sortedPieData[i].name);
    }
  }

  var options = {
    chart: {
      type: "bar",
      height: "156px",

      spacingTop: 0,
      margin: [-60, 0, 0, 0],
    },
    title: {
      text: null,
    },
    xAxis: {
      //categories: categories,
      labels: {
        enabled: false,
      },
      visible: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      //stackLabels: {
      //    enabled: false,
      //    style: {
      //        fontWeight: 'bold',
      //        color: ( // theme
      //            Highcharts.defaultOptions.title.style &&
      //            Highcharts.defaultOptions.title.style.color
      //        ) || 'gray'
      //    }
      //},
      max: 100,
      labels: {
        enabled: true,
      },
      visible: false,
      tickAmount: 0,
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: false,
      headerFormat: null,
    },
    plotOptions: {
      bar: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          format: "{series.name} ({point.y})",
          style: {
            fontWeight: "normal",
            textOutline: "none",
          },



          filter: {
            property: 'percentage',
            operator: '>',
            value: 15
        },
        },
      
      
      },
    },

    series: sortedPieData,
    legend: {
      enabled: true,
    },

    credits: {
      enabled: false,
    },
  };

  if (isLoading === true) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return <HighchartsReact highcharts={Highcharts} options={options} isLoading={isLoading} />;
};