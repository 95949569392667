import React, { useContext, useState, useEffect } from "react";
import { useMany, HttpError } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { DashboardList } from "components/dashboard/list";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { useParams } from "react-router-dom";

export const ErrorPage: React.FC = () => {
  const params = useParams();

  let errorCode: Number = 500;

  return (
    <>
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl mt-10">
        <div className="flex-row-fluid" id="content">
          <div id="content_container" className="d-flex flex-column-fluid text-center card card-flush w-100 py-5">
            <div className="card-body py-15 py-lg-20">
              <h1 className="fw-bolder fs-3qx text-gray-900 mb-4">Oops!</h1>

              <div className="fw-semibold fs-6 text-gray-700 mb-7">A system error has occurred. We're working on it and we'll get it fixed as soon possible.</div>

              <div className="mb-11"></div>

              <div className="mb-0">
                <a href="/dashboard" className="btn btn-sm btn-primary">
                  Return to Dashboard
                </a>
                <span className="d-none">{process.env.REACT_APP_HSVERSION}</span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
