import { useEffect, useState } from "react";
import { RecordStatus } from "types/enums";
import { parseCamelCaseToSpaces } from "utils/strings";

export type ApprovalBadgeProps = {
  status: RecordStatus;
};

export const ApprovalBadge: React.FC<ApprovalBadgeProps> = ({ status }) => {
  const [statusStyle, setStatusStyle] = useState<string | null>(null);

  useEffect(() => {
    switch (status ?? RecordStatus.Draft) {
      case RecordStatus.Draft:
        setStatusStyle("secondary");
        break;
      case RecordStatus.Submitted:
      case RecordStatus.SubmittedToCore:
      case RecordStatus.SubmittedToFiscal:
      case RecordStatus.SubmittedToSAMHSA:
        setStatusStyle("warning text-dark");
        break;
      case RecordStatus.Approved:
      case RecordStatus.InProgress:
      case RecordStatus.Completed:
        setStatusStyle("success");
        break;
      case RecordStatus.NotApproved:
        setStatusStyle("danger");
        break;
      case RecordStatus.EvaluationPending:
      case RecordStatus.EvaluationSent:
        setStatusStyle("info");
        break;
      default:
        setStatusStyle("primary");
        break;
    }
  }, [status]);

  return (
    <>
      <span className={`badge badge-${statusStyle ?? "primary"} fw-bold align-middle`}>{parseCamelCaseToSpaces(status)}</span>
    </>
  );
};
