import React, { useState, useEffect, useContext } from "react";
import { Control, Controller, UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { NumericFormat } from "react-number-format";
import { LookupsContext } from "contexts/lookupsContext";
import { API_URL } from "App";
import { axiosInstance } from "index";
type GranteeAttendanceProps = {
  activityId: number | undefined;
  control: Control<BaseRecord, any>;
  getValues: UseFormGetValues<BaseRecord>;
  setValue: UseFormSetValue<BaseRecord>;
};

export const GranteeAttendanceConsultation: React.FC<GranteeAttendanceProps> = ({ activityId, control, getValues, setValue }) => {
  const [attendanceData, setAttendanceData] = useState<BaseRecord[]>();
  const { getLookupDescription } = useContext(LookupsContext);

  useEffect(() => {
    if (!getValues("granteeAttendancesConsultation")) return;
    setAttendanceData(getValues("granteeAttendancesConsultation"));
  }, []);

  const handleRefresh = async () => {
    try {
      const data = getValues();
      const response = await axiosInstance.post(`${API_URL}/activities/${activityId}/attendance/consultation`, data);
      setValue("granteeAttendancesConsultation", response?.data);

      setAttendanceData(response?.data);
    } catch (error) {
      console.error(error); // TODO: Handle error
    }
  };

  return (
    <>
      {/* begin::T/TA Activity Clients Attended */}
      <div className="d-flex flex-column gap-5 gap-md-7">
        <div className="row g-5">
          <div className="col-md-6">
            <h3 className="h4 mb-0">T/TA Consultation</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button type="button" className="btn btn-sm btn-dark" style={{ marginTop: "-15px" }} onClick={handleRefresh}>
              <i className="fa-solid fa-arrows-rotate"></i> Refresh
            </button>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-stripe">
                <thead className="table-light">
                  <tr className="fw-bold mb-2 align-top">
                    <th id="GranteeGrant" scope="col">
                      Grantee & Grant Program
                    </th>
                    <th id="AttendeeNum" scope="col">
                      # of Attendees
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceData?.map((attendance, index) => (
                    <tr>
                      <th id="grant1" scope="row">
                        {getLookupDescription(attendance.regionCode)} &gt;&nbsp;
                        {getLookupDescription(attendance.granteeTypeCode)} &gt;&nbsp;
                        {getLookupDescription(attendance.granteeCode)} &gt;&nbsp;
                        {attendance.grantProgramCode}
                      </th>
                      <td>
                        <Controller
                          aria-labelledby="AttendeeNum"
                          name={`granteeAttendancesConsultation[${index}].numberOfAttendees`}
                          control={control}
                          render={({ field: { onChange, value }, ...props }) => (
                            <NumericFormat
                              className="form-control"
                              thousandSeparator={true}
                              decimalScale={0}
                              allowNegative={false}
                              defaultValue={0}
                              value={attendance.numberOfAttendees}
                              onChange={onChange}
                            />
                          )}
                        />
                      </td>
                    </tr>
                  ))}
                  {/* {granteeAttendanceFields.map((attendance, index) => (
                    <tr>
                      <th id="grant1" scope="row">
                        {getLookupDescription(getValues(`granteeAttendancesConsultation[${index}].regionCode`))} &gt;&nbsp;
                        {getLookupDescription(getValues(`granteeAttendancesConsultation[${index}].granteeTypeCode`))} &gt;&nbsp;
                        {getLookupDescription(getValues(`granteeAttendancesConsultation[${index}].granteeCode`))} &gt;&nbsp;
                        {getValues(`granteeAttendancesConsultation[${index}].grantProgramCode`)}
                      </th>
                      <td>
                        <Controller
                          aria-labelledby="AttendeeNum"
                          name={`granteeAttendancesConsultation[${index}].numberOfAttendees`}
                          control={control}
                          render={({ field: { onChange, value }, ...props }) => <NumericFormat className="form-control" thousandSeparator={true} decimalScale={0} allowNegative={false} defaultValue={0} value={value} onChange={onChange} />}
                        />
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </div>
      {/* end::T/TA Activity Clients Attended */}
    </>
  );
};
