import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { idID } from "@mui/material/locale";

type HSChartParams = {
  reportCode: string;
  chartData: { name: string; y: number }[]
  isLoading?: boolean;
};
require('highcharts/modules/series-label')(Highcharts);

export const HSColumnChart: React.FC<HSChartParams> = ({reportCode, chartData, isLoading}) => {
  const { getLookupDescription, getLookupDescriptions } =
    useContext(LookupsContext);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);


  

  const [ chartResult, setChartResult] = useState(null);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  
  useEffect(() => {
    
  }, []);

 // const getDashboardChart = async (reportCode: string) => {
 //   const response = await axiosInstance.get(`${API_URL}/reports/chart`, {
 //     params: {
 //       reportCode: reportCode,
 //     },
 //   });
 
 // };

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },

    xAxis: {
      type: "category",
      uniqueNames: false,
      labels: {

        style: {
          fontSize: 12
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    tooltip: {
      
      
      pointFormat: "<b>{point.y}</b>",
      shared: true,
      style: {
        fontSize: 14
      }
      
      
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,

        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            textShadow: false,
            fontSize: 14,
            textOutline: false
          }
        }
      },
    },
    series: [
      {
        label: {
          enabled: true
        },
        data: chartData,
        showInLegend: false,
        color: '#4c5f90'
      },
    ],
    credits: {
      enabled: false,
    },
  };





if (isLoading === true)
{
  return (
    <Box sx={{ display: "flex" }}>
            <CircularProgress color="primary" />
          </Box>
    
    );
}


  return <HighchartsReact highcharts={Highcharts} options={options} isLoading={isLoading} />;
};
