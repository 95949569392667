import React, { useState } from "react";
import { Card, Button, CardContent, Paper, Typography } from "@mui/material";
import { useLogin } from "@refinedev/core";

import { Stack } from "@mui/system";
import { styled } from "@mui/material/styles";
import logo from "assets/media/logos/sptac-logo.png";
import { DialogClasses, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const Login: React.FC = () => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState<boolean>(false);

  const SignInButton = () => {
    const { mutate: login } = useLogin();
    return (
      <button type="button" className="btn btn-sm btn-primary mr-5" onClick={() => login({})} role="button">
        Agree & Sign-in
      </button>
    );
  };

  const openPrivacy = () => {
    isPrivacyOpen === true ? setIsPrivacyOpen(false) : setIsPrivacyOpen(true);
  };

  let privacyDialogContent = (
    <div>
      <DialogTitle>
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            System Use Notification
          </h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={openPrivacy}></button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <p>
                This warning banner provides privacy and security notices consistent with applicable federal laws, directives, and other federal guidance for accessing this Government system, which
                includes (1) this computer network, (2) all computers connected to this network, and (3) all devices and storage media attached to this network or to a computer on this network.
              </p>
              <p>This system is provided for Government-authorized use only.</p>

              <p>Unauthorized or improper use of this system is prohibited and may result in disciplinary action and/or civil and criminal penalties.</p>
              <p>Personal use of social media and networking sites on this system is limited as to not interfere with official work duties and is subject to monitoring.</p>
              <p>
                By using this system, you understand and consent to the following:
                <ul>
                  <li>
                    The Government may monitor, record, and audit your system usage, including usage of personal devices and email systems for official duties or to conduct HHS business. Therefore,
                    you have no reasonable expectation of privacy regarding any communication or data transiting or stored on this system. At any time, and for any lawful Government purpose, the
                    government may monitor, intercept, and search and seize any communication or data transiting or stored on this system.
                  </li>
                  <li>Any communication or data transiting or stored on this system may be disclosed or used for any lawful Government purpose.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="modal-footer">
          <SignInButton />

          <button type="button" className="btn btn-sm btn-secondary" onClick={openPrivacy} data-bs-dismiss="modal">
            I Do Not Agree
          </button>
        </div>
      </DialogActions>
    </div>
  );

  return (
    <body style={{ backgroundImage: "none!important", backgroundColor: "#efefef!important", paddingTop: "100px" }}>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <main className="card card-flush w-md-650px py-5">
              <div className="card-body py-15 py-lg-20">
                <div className="mb-7">
                  <a href="/" className="">
                    <img alt="Strategic Prevention Technical Assistance Center Logo" src="assets/media/logos/sptac-logo.png" className="h-50px h-lg-70px me-2 me-lg-9" />
                  </a>
                </div>

                <h1 className="fw-bolder text-gray-900 mb-5">Welcome to the SPTAC Data System & Dashboard</h1>

                <div className="fw-semibold fs-6 text-gray-700 mb-7">
                  The Strategic Prevention Technical Assistance Center (SPTAC) is a SAMHSA national training and technical assistance (T/TA) center dedicated to advancing the application of culturally
                  responsive, evidence-based substance misuse prevention programs, guided by SAMHSA’s Strategic Prevention Framework.
                </div>

                <button type="button" className="btn btn-sm btn-primary text-white" onClick={openPrivacy} data-bs-toggle="modal">
                  Signin via Active Directory
                </button>

                <Dialog open={isPrivacyOpen} children={privacyDialogContent} />

                <div className="text-gray-700 text-center fw-semibold fs-8 text-uppercase gs-0 mt-5">
                  <a href="https://www.samhsa.gov/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </body>
  );
};