import React, { useEffect, ReactNode } from "react";
import { Header, Footer } from "components";



type LayoutProps = {
  children: ReactNode;
};

export const MainLayout: React.FC<LayoutProps> = ({ children }) => {
  
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Set the script source to your external JavaScript file
    //script.src = "assets/js/scripts.bundle.js";
    script.src = `${process.env.REACT_APP_AZURE_AAD_REDIRECT_URI}assets/js/scripts.bundle.js`;
    // Append the script element to the document's body
    document.body.appendChild(script);

    // Clean up the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* begin::Main */}
      {/* begin::Root */}
      <div className="d-flex flex-column flex-root">
        {/* begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/* begin::Wrapper */}
          <div className="wrapper d-flex flex-column flex-row-fluid" id="wrapper">
            <a className="sr-only sr-only-focusable link-dark fw-bold fs-5 text-decoration-underline" href="#content_container">
              Skip to main content
            </a>
            <Header />
            {/* <BreadcrumbProvider> */}

            {children}

            {/* </BreadcrumbProvider> */}
            <Footer />
          </div>
        </div>
      </div>
      {/* end::Root */}
      {/* end::Main */}
    </>
  );
};
