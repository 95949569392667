import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type AlertDialogProps = {
  open: boolean;
  title: string;
  content: string;
  confirmButtonText?: string;
  onConfirm: () => void;
  cancelButtonText?: string;
  onCancel?: () => void;
};

export const AlertDialog: React.FC<AlertDialogProps> = ({ open, title, content, confirmButtonText, onConfirm, onCancel }) => {
  return (
    <div>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {title}
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onCancel}></button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="modal-body text-dark">{content}</div>
        </DialogContent>
        <DialogActions>
          <div className="modal-footer">
            <button type="button" onClick={onConfirm} className={`btn btn-link mx-5 my-5`}>
              {confirmButtonText != null ? confirmButtonText : "Confirm"}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
