export function parseCamelCaseToSpaces(input: string | undefined, useAmpersand: boolean = false): string | undefined {
  if (input === undefined) return input;
  const result = input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between lowercase and uppercase letters
    .replace(/([A-Z]{2,})([A-Z][a-z])/g, "$1 $2") // Insert space between consecutive uppercase letters followed by a lowercase letter
    .replace(/([a-z])([0-9])/gi, "$1 $2") // Insert space between lowercase letter and digit
    .replace(/([0-9])([a-z])/gi, "$1 $2") // Insert space between digit and lowercase letter
    .trim(); // Trim any leading/trailing spaces
  return useAmpersand ? result.replace(/ and /gi, " & ") : result;
}

export function tryAddToStringArray(input: string | null, array: string[]): void {
  if (input === null) return;
  array.push(input);
}


export function hsToStringCurrency(input: number): string
{
  if (input != null)
  {
  return input.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }


  return "";
}