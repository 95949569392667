import React, { createContext, useState, useEffect, ReactNode } from "react";
import { BaseRecord } from "@refinedev/core";
import dataProvider from "@refinedev/simple-rest";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { GranteeSelectionOption } from "../types/granteeSelectionOption";
import { GranteeSelectionOptionType, LookupType } from "types/enums";

interface GranteeSelectionContextProps {
  regions: GranteeSelectionOption[];
  granteeTypes: GranteeSelectionOption[];
  grantees: GranteeSelectionOption[];
  grantPrograms: GranteeSelectionOption[];
  // getGranteesSelectionOptionsByType: (type: GranteeSelectionOptionType) => GranteeSelectionOption[];
  getGranteesByRegionAndType: (region: string, granteeType: string) => GranteeSelectionOption[];
}

export const GranteeSelectionContext = createContext<GranteeSelectionContextProps>({
  regions: [],
  granteeTypes: [],
  grantees: [],
  grantPrograms: [],
  // getGranteesSelectionOptionsByType: () => [],
  getGranteesByRegionAndType: () => [],
});

type GranteeSelectionProviderProps = {
  children?: ReactNode;
};

const GranteeSelectionProvider: React.FC<GranteeSelectionProviderProps> = ({ children }) => {
  const [regions, setRegions] = useState<GranteeSelectionOption[]>([]);
  const [granteeTypes, setGranteeTypes] = useState<GranteeSelectionOption[]>([]);
  const [grantees, setGrantees] = useState<GranteeSelectionOption[]>([]);
  const [grantPrograms, setGrantPrograms] = useState<GranteeSelectionOption[]>([]);

  const [granteeRelationships, setGranteeRelationships] = useState<BaseRecord[]>([]);

  const setLookups = (data: BaseRecord[]) => {
    const dataCopy = [...data];
    setRegions(
      dataCopy
        ?.filter((lookup) => lookup.lookupType === LookupType.Regions)
        ?.sort((a, b) => a.displayOrder - b.displayOrder)
        ?.map((lookup) => ({ name: lookup.lookupDescription, value: lookup.lookupCode }))
    );
    setGranteeTypes(
      dataCopy
        ?.filter((lookup) => lookup.lookupType === LookupType.GranteeTypes)
        ?.sort((a, b) => a.displayOrder - b.displayOrder)
        ?.map((lookup) => ({ name: lookup.lookupDescription, value: lookup.lookupCode }))
    );
    setGrantees(
      dataCopy
        ?.filter((lookup) => lookup.lookupType === LookupType.Grantees)
        ?.sort((a, b) => a.displayOrder - b.displayOrder)
        ?.map((lookup) => ({ name: lookup.lookupDescription, value: lookup.lookupCode }))
    );
    setGrantPrograms(
      dataCopy
        ?.filter((lookup) => lookup.lookupType === LookupType.GrantPrograms)
        ?.sort((a, b) => a.lookupCode.localeCompare(b.lookupCode)) //Alphabetical -> ok to ignore standard lookup.displayOrder?
        ?.map((lookup) => ({ name: lookup.lookupDescription, value: lookup.lookupCode }))
    );
  };

  useEffect(() => {
    const setLookupsFromApi = async () => {
      const result = await dataProvider(API_URL, axiosInstance).getMany({ resource: "lookups", ids: [] });
      setLookups(result?.data ?? []);
    };
    setLookupsFromApi();

    const setGranteeRelationshipsFromApi = async () => {
      const result = await dataProvider(API_URL, axiosInstance).getMany({ resource: "lookups/granteerelationships", ids: [] });
      setGranteeRelationships(result?.data ?? []);
    };
    setGranteeRelationshipsFromApi();
  }, []);

  // const getGranteesSelectionOptionsByType = (type: GranteeSelectionOptionType): GranteeSelectionOption[] => {
  //   let result: GranteeSelectionOption[] = [];
  //   switch (type) {
  //     case GranteeSelectionOptionType.AllRegions:
  //       result = regions;
  //       break;
  //     case GranteeSelectionOptionType.AllGranteeTypes:
  //       result = granteeTypes;
  //       break;
  //     case GranteeSelectionOptionType.AllGrantees:
  //       result = grantees;
  //       break;
  //     case GranteeSelectionOptionType.AllGrantPrograms:
  //       result = grantPrograms;
  //       break;
  //     default:
  //       break;
  //   }
  //   return result;
  // };

  const getGranteesByRegionAndType = (region: string, granteeType: string): GranteeSelectionOption[] => {
    if (region === GranteeSelectionOptionType.AllRegions || granteeType === GranteeSelectionOptionType.AllGranteeTypes) {
      return [{ name: "All Grantees", value: GranteeSelectionOptionType.AllGrantees }];
    }

    let result: GranteeSelectionOption[] = [];
    const granteeRelationshipsForRegionAndType = granteeRelationships.filter((granteeRelationship) => granteeRelationship.regionCode === region && granteeRelationship.granteeTypeCode === granteeType);
    //console.log("granteeRelationshipsForRegionAndType", region, granteeType, granteeRelationshipsForRegionAndType);
    if (granteeRelationshipsForRegionAndType.length > 0) {
      const granteeRelationshipsForRegionAndTypeCopy = [...granteeRelationshipsForRegionAndType];
      result = granteeRelationshipsForRegionAndTypeCopy
        ?.sort((a, b) => (a.granteeName ?? a.granteeCode).localeCompare(b.granteeName ?? b.granteeCode))
        ?.map((granteeRelationship) => {
          return { name: granteeRelationship.granteeName ?? granteeRelationship.granteeCode, value: granteeRelationship.granteeCode };
        });
      //add AllGrantees at top of result
      result.unshift({ name: "All Grantees", value: GranteeSelectionOptionType.AllGrantees });
    } else {
      //TODO: handle missing grantee relationships in UI
      result = [{ name: "All Grantees *", value: GranteeSelectionOptionType.AllGrantees }];
    }
    return result;
  };

  return <GranteeSelectionContext.Provider value={{ regions, granteeTypes, grantees, grantPrograms, getGranteesByRegionAndType }}>{children}</GranteeSelectionContext.Provider>;
};

export default GranteeSelectionProvider;
