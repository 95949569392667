import React, { useContext, useEffect } from "react";
import { Collapse } from "@mui/material";
import { useForm } from "react-hook-form";
import { LookupsContext } from "contexts/lookupsContext";
import { GranteeSelect } from "components";
import { GranteeSelectionOptionType, GranteeSelectionFilterType } from "types/enums";
import { FilterCriteria } from "types/filterCriteria";
import { tryAddToStringArray } from "utils/strings";

const defaultFilterValues = {
  granteeSelections: [
    {
      regionCode: GranteeSelectionOptionType.AllRegions,
      granteeTypeCode: GranteeSelectionOptionType.AllGranteeTypes,
      granteeCode: GranteeSelectionOptionType.AllGrantees,
      grantProgramCode: GranteeSelectionOptionType.AllGrantPrograms,
    },
  ],
  population: "",
  audience: "",
  focusArea: "",
  coaching: "",
  statuses: [],
  includeApprovedNotComplete: false,
};

type ServiceRequestFilterCriteriaProps = {
  filterResults: (filter: FilterCriteria) => void;
  clearFilter: () => void;
  granteeSelectionFilterType?: GranteeSelectionFilterType;
  isFilterExpanded?: boolean;
};

export const ServiceRequestFilterCriteria: React.FC<ServiceRequestFilterCriteriaProps> = ({ filterResults, clearFilter, granteeSelectionFilterType, isFilterExpanded }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { getLookupDescription } = useContext(LookupsContext);

  const { register, control, setValue, getValues, reset } = useForm();

  const handleClearFilter = () => {
    reset(defaultFilterValues);
    clearFilter();
  };

  const handleFilterCriteria = () => {
    let oDataFilters: string[] = [];
    let filterDescriptions: string[] = [];

    //Grantee Selections
    const granteeSelectionFilter = getGranteeSelectionFilter();
    tryAddToStringArray(granteeSelectionFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(granteeSelectionFilter.filterDescription, filterDescriptions);

    const filter: FilterCriteria = {
      oDataFilter: oDataFilters.length > 0 ? oDataFilters.join(" AND ") : null,
      filterDescription: filterDescriptions.length > 0 ? filterDescriptions.join(" | ") : null,
      filterForm: control,
    };
    //console.log("filter", filter);
    //debug: TODO: max limit may be around 2048 characters, need to verify
    //console.log("oDataFilter length: ", filter.oDataFilter?.length);
    filterResults(filter);
    setExpanded(false);
  };

  const getGranteeSelectionFilter = (): FilterCriteria => {
    let filter: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filters: string[] = [];
    let descriptions: string[] = [];
    const granteeSelection = getValues("granteeSelections")[0];

    if (granteeSelection !== undefined) {
      // HACK: Checking for empty string on Codes is a hack to get around the fact that the granteeSelections object is not being set properly when the form is reset
      if (granteeSelection.regionCode !== GranteeSelectionOptionType.AllRegions && granteeSelection.regionCode.trim() !== "") {
        filters.push("gs/regionCode eq '" + granteeSelection.regionCode + "'");
        descriptions.push("Region: " + getLookupDescription(granteeSelection.regionCode));
      }
      if (granteeSelection.granteeTypeCode !== GranteeSelectionOptionType.AllGranteeTypes && granteeSelection.granteeTypeCode.trim() !== "") {
        filters.push("gs/granteeTypeCode eq '" + granteeSelection.granteeTypeCode + "'");
        descriptions.push("Grantee Type: " + getLookupDescription(granteeSelection.granteeTypeCode));
      }
      if (granteeSelection.granteeCode !== GranteeSelectionOptionType.AllGrantees && granteeSelection.granteeCode.trim() !== "") {
        filters.push("gs/granteeCode eq '" + granteeSelection.granteeCode + "'");
        descriptions.push("Grantee: " + getLookupDescription(granteeSelection.granteeCode));
      }
      if (granteeSelection.grantProgramCode !== GranteeSelectionOptionType.AllGrantPrograms && granteeSelection.grantProgramCode.trim() !== "") {
        filters.push("gs/grantProgramCode eq '" + granteeSelection.grantProgramCode + "'");
        descriptions.push("Grant Program: " + granteeSelection.grantProgramCode); //Grant Programs are displayed as just their codes
      }
    }

    if (granteeSelectionFilterType === undefined || granteeSelectionFilterType === GranteeSelectionFilterType.Attended) {
      //Default filter type is on "Attended"
      filter.oDataFilter = filters.length > 0 ? "(granteeAttendancesConsultation/any(gs: " + filters.join(" and ") + ") or granteeAttendancesEvent/any(gs: " + filters.join(" and ") + "))" : null;
    } else {
      //Filter on "Invited"
      filter.oDataFilter = filters.length > 0 ? "granteeSelections/any(gs: " + filters.join(" and ") + ")" : null;
    }
    filter.filterDescription = descriptions.length > 0 ? descriptions.join(" | ") : null;
    return filter;
  };

  useEffect(() => {
    if (isFilterExpanded != null) {
      setExpanded(isFilterExpanded);
    }
  }, [expanded, isFilterExpanded]);

  return (
    <div className={`card ${expanded ? "mb-5 mb-xl-10" : ""}`}>
      <div className="d-flex justify-content-end me-10"></div>
      <Collapse in={expanded}>
        <div className="card-body">
          <div className="form-group row">
            {/* begin::First Row (Region & Grantees) */}
            <div className="row">
              <GranteeSelect register={register} control={control} setValue={setValue} getValues={getValues} index={0} formFieldName="granteeSelections" />
            </div>
            {/* end::First Row (Region & Grantees) */}
          </div>
        </div>

        {/* begin::Filter Card Footer) */}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" className="btn btn-link me-10" onClick={handleClearFilter}>
            Reset
          </button>
          <button type="button" className="btn btn-primary btn-outline-primary" id="kt_account_profile_details_submit" onClick={handleFilterCriteria}>
            View Results
          </button>
        </div>
        {/* end::Filter Card Footer) */}
      </Collapse>
    </div>
  );
};
