import React, { useEffect, useState, useContext } from "react";
import { UseFormRegister, Control, useFieldArray, Controller, UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

type HourlyStaffExpenseProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
  staff: BaseRecord[];
  lookups: BaseRecord[];
};

export const HourlyStaffExpense: React.FC<HourlyStaffExpenseProps> = ({ register, control, setValue, getValues, staff, lookups }) => {
  const [estimatedHoursTotal, setEstimatedHoursTotal] = useState<Number>(0);
  const [actualHoursTotal, setActualHoursTotal] = useState<Number>(0);
  const [sumEstimatedTotal, setSumEstimatedTotal] = useState<Number>(0);
  const [sumActualTotal, setSumActualTotal] = useState<Number>(0);
  const { userPermissions } = useContext(LoggedInUserContext);

  const defaultHourlyExpense = { staffUserId: "", subTypeCode: "", estimatedHours: 0, actualHours: 0, staffRateUsd: 0, estimatedTotal: 0, actualTotal: 0 };

  const {
    fields: hourlyExpenseFields,
    append: appendHourlyExpense,
    remove: removeHourlyExpense,
  } = useFieldArray({
    control,
    name: "hourlyExpenses",
  });

  useEffect(() => {
    control?._formValues?.hourlyExpenses?.length === 0 && appendHourlyExpense(defaultHourlyExpense);
  }, []);

  useEffect(() => {
    sumTotals();
  }, [control._formValues.hourlyExpenses]);

  const handleStaffChange = (field: string, index: number, value: string) => {
    //Set changing field
    setValue(field, value);
    //Set staff rate
    const staffMember = staff.find((staff) => staff.userId === value);
    const staffRates = staffMember?.staffRates;
    const mostRecentRate = staffRates?.sort((a: any, b: any) => dayjs(b.rateAsOfDate).diff(dayjs(a.rateAsOfDate)))[0];
    //console.log("mostRecentRate", mostRecentRate);
    const estimatedHours = getValues(`hourlyExpenses[${index}].estimatedHours`);
    //console.log("estimatedHours", estimatedHours);
    const actualHours = getValues(`hourlyExpenses[${index}].actualHours`);
    //console.log("actualHours", actualHours);
    setValue(`hourlyExpenses[${index}].staffRateUsd`, mostRecentRate?.rateUsd ?? 0);
    setValue(`hourlyExpenses[${index}].estimatedTotal`, estimatedHours ? (mostRecentRate?.rateUsd ?? 0) * estimatedHours : 0);
    setValue(`hourlyExpenses[${index}].actualTotal`, actualHours ? (mostRecentRate?.rateUsd ?? 0) * actualHours : 0);
    sumTotals();
  };

  const handleHourChange = (field: string, rateField: string, sumField: string, value: string) => {
    let valueConverted = parseFloat(value.replace(/[^0-9.-]/g, ""));
    if (isNaN(valueConverted)) valueConverted = 0;

    if (value === ".") {
      setValue(field, value);
    } else {
      setValue(field, valueConverted);
    }

    const rate = getValues(rateField);
    rate && setValue(sumField, Number(valueConverted) * rate);
    sumTotals();
  };

  const sumTotals = () => {
    let sumEstimatedHours = 0;
    let sumActualHours = 0;
    let sumEstimated = 0;
    let sumActual = 0;

    //for (const expense of control?._formValues?.hourlyExpenses) {
    const hourlyExpenses = getValues("hourlyExpenses");
    if (hourlyExpenses && hourlyExpenses?.length > 0) {
      for (const expense of hourlyExpenses) {
        sumEstimatedHours += Number(isNaN(expense?.estimatedHours) ? 0 : expense?.estimatedHours);
        sumActualHours += Number(isNaN(expense?.actualHours) ? 0 : expense?.actualHours);
        sumEstimated += Number(expense?.estimatedTotal ?? 0);
        sumActual += Number(expense?.actualTotal ?? 0);
      }
    }

    setEstimatedHoursTotal(sumEstimatedHours);
    setActualHoursTotal(sumActualHours);
    setSumEstimatedTotal(sumEstimated);
    setSumActualTotal(sumActual);
  };

  return (
    <>
      
      <h3 className="h4 mb-0">Hours & Rates</h3>

      <div className="col-sm-12">
        <div className="table-responsive">
          <table className="table table-stripe">
            <thead className="table-light">
              <tr className="fw-bold mb-2 align-top">
                <th id="staffName" scope="col" className="fw-bold">
                  Staff Member
                </th>
                <th id="staffType" scope="col" className="fw-bold">
                  Type
                </th>
                <th id="staffEst" scope="col" className="fw-bold">
                  Estimated Hours
                </th>
                <th id="staffActual" scope="col" className="fw-bold">
                  Actual Hours
                </th>
                <th id="staffRate" scope="col" className="fw-bold" hidden={!userPermissions.canViewStaffRates}>
                  Rate
                </th>
                <th id="staffCalc" scope="col" className="fw-bold" hidden={!userPermissions.canViewStaffRates}>
                  Estimated Totals
                </th>
                <th id="staffCalc" scope="col" className="fw-bold" hidden={!userPermissions.canViewStaffRates}>
                  Actual Totals
                </th>
                <th>
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {hourlyExpenseFields.map((expense, index) => (
                <tr>
                  <td>
                    <label className="sr-only" htmlFor={`staffPlan_${expense.id}`}>
                      Staff Member
                    </label>
                    <select
                      key={expense.id}
                      id={`staffPlan_${expense.id}`}
                      {...register(`hourlyExpenses[${index}].staffUserId` as const, { required: false })}
                      className="form-select"
                      onChange={(e: any) => handleStaffChange(`hourlyExpenses[${index}].staffUserId`, index, e.target.value)}
                    >
                      <option value="">Select...</option>
                      {staff?.map((user, index) => (
                        <option key={index} value={user.userId}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <label className="sr-only" htmlFor={`staffExpenseHourly_${expense.id}`}>
                      Staff Type
                    </label>
                    <select key={expense.id} id={`staffExpenseHourly_${expense.id}`} {...register(`hourlyExpenses[${index}].subTypeCode`, { required: false })} className="form-select mb-3">
                      <option value="">Select...</option>
                      {lookups
                        .filter((lookup) => {
                          return lookup.lookupType === "StaffExpenseHourly";
                        })
                        .map((lookup, index) => (
                          <option key={index} value={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <label className="sr-only" htmlFor={`hourlyExpenseEstimatedHours_${expense.id}`}>
                      Estimated Hours
                    </label>
                    <Controller
                      key={expense.id}
                      name={`hourlyExpenses[${index}].estimatedHours`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          className="form-control"
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          allowLeadingZeros
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            let { floatValue } = values;
                            //if (floatValue !== undefined && values.value === ".") {
                            //  floatValue = 0;
                            //}
                            return floatValue !== undefined ? floatValue < 1000000 : true;
                          }}
                          onValueChange={(e: any) =>
                            handleHourChange(`hourlyExpenses[${index}].estimatedHours`, `hourlyExpenses[${index}].staffRateUsd`, `hourlyExpenses[${index}].estimatedTotal`, e.value)
                          }
                          id={`hourlyExpenseEstimatedHours_${expense.id}`}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <label className="sr-only" htmlFor={`hourlyExpensesActualHours_${expense.id}`}>
                      Actual Hours
                    </label>
                    <Controller
                      key={expense.id}
                      name={`hourlyExpenses[${index}].actualHours`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          className="form-control"
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          allowLeadingZeros
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            let { floatValue } = values;
                            //if (floatValue !== undefined && values.value === ".") {
                            //  floatValue = 0;
                            //}
                            return floatValue !== undefined ? floatValue < 1000000 : true;
                          }}
                          onValueChange={(e: any) => handleHourChange(`hourlyExpenses[${index}].actualHours`, `hourlyExpenses[${index}].staffRateUsd`, `hourlyExpenses[${index}].actualTotal`, e.value)}
                          id={`hourlyExpensesActualHours_${expense.id}`}
                        />
                      )}
                    />
                  </td>
                  <td className="td-100" hidden={!userPermissions.canViewStaffRates}>
                    <label className="sr-only" htmlFor={`hourlyExpensesStaffRateUsd_${expense.id}`}>
                      Staff Rate USD
                    </label>
                    <Controller
                      key={expense.id}
                      name={`hourlyExpenses[${index}].staffRateUsd`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          className="form-control-plaintext"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          disabled={true}
                          id={`hourlyExpensesStaffRateUsd_${expense.id}`}
                        />
                      )}
                    />
                  </td>
                  <td className="td-100" hidden={!userPermissions.canViewStaffRates}>
                    <label className="sr-only" htmlFor={`hourlyExpensesEstimatedTotal_${expense.id}`}>
                      Estimated Total
                    </label>
                    <Controller
                      key={expense.id}
                      name={`hourlyExpenses[${index}].estimatedTotal`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          className="form-control-plaintext"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          disabled={true}
                          id={`hourlyExpensesEstimatedTotal_${expense.id}`}
                        />
                      )}
                    />
                  </td>
                  <td className="td-100" hidden={!userPermissions.canViewStaffRates}>
                    <label className="sr-only" htmlFor={`hourlyExpensesActualTotal_${expense.id}`}>
                      Actual Total
                    </label>
                    <Controller
                      key={expense.id}
                      name={`hourlyExpenses[${index}].actualTotal`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          className="form-control-plaintext"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          disabled={true}
                          id={`hourlyExpensesActualTotal_${expense.id}`}
                        />
                      )}
                    />
                  </td>
                  <td style={{ minWidth: "40px", paddingTop: "2%" }}>
                    <a key={expense.id} hidden={getValues(`hourlyExpenses`).length === 1} style={{ cursor: "pointer" }} onClick={() => removeHourlyExpense(index)}>
                      <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="table-footer-divider">
              <tr className="fw-bold">
                <th scope="row">Staff Totals</th>
                <td></td>
                <td>{Number(estimatedHoursTotal).toLocaleString()} hours</td>
                <td>{Number(actualHoursTotal).toLocaleString()} hours</td>
                <td hidden={!userPermissions.canViewStaffRates}></td>
                <td hidden={!userPermissions.canViewStaffRates}>{Number(sumEstimatedTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                <td hidden={!userPermissions.canViewStaffRates}>{Number(sumActualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div className="col-sm-12 mt-0 mb-5">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => appendHourlyExpense(defaultHourlyExpense)}>
          Add More
        </button>
      </div>
    </>
  );
};
