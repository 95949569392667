import React, { useEffect } from "react";
import { UseFormRegister, Control, useFieldArray, Controller, UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { NumericFormat } from "react-number-format";

type TransportationStaffExpenseProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
  staff: BaseRecord[];
  lookups: BaseRecord[];
};

export const TransportationStaffExpense: React.FC<TransportationStaffExpenseProps> = ({ register, control, setValue, getValues, staff, lookups }) => {
  const defaultTransportationExpense = { staffUserId: "", subTypeCode: "", estimatedExpense: 0, actualExpense: 0 };

  const {
    fields: transportationExpenseFields,
    append: appendTransportationExpense,
    remove: removeTransportationExpense,
  } = useFieldArray({
    control,
    name: "transportationExpenses",
  });

  useEffect(() => {
    control?._formValues?.transportationExpenses?.length === 0 && appendTransportationExpense(defaultTransportationExpense);
  }, []);

  const handleExpenseChange = (field: string, value: string) => {
    let valueConverted = parseFloat(value.replace(/[^0-9.-]/g, ""));
    if (isNaN(valueConverted)) valueConverted = 0;

    if (value === ".") {
      setValue(field, value);
    } else {
      setValue(field, valueConverted);
    }
  };

  return (
    <>
      <h3 className="h4 mb-0">Airfare, Ground, Other</h3>

      <div className="col-sm-12">
        <div className="table-responsive">
          <table className="table table-stripe">
            <thead className="table-light">
              <tr className="fw-bold mb-2 align-top">
                <th id="ExpNameAir" scope="col" className="ps-1" style={{ width: "20%" }}>
                  Staff Member
                </th>
                <th id="ExpCatAir" scope="col" style={{ width: "20%" }}>
                  Expense Category
                </th>
                <th id="ExpEstAir" scope="col" style={{ width: "20%" }}>
                  Estimated Expense
                </th>
                <th id="ExpActAir" scope="col" style={{ width: "20%" }}>
                  Actual Expense
                </th>
                <th><span className="sr-only">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              {transportationExpenseFields.map((expense, index) => (
                <>
                  <tr>
                    <td className="td-150">
                      <select key={expense.id} id="staffPlan" aria-labelledby="ExpNameAir" {...register(`transportationExpenses[${index}].staffUserId` as const, { required: false })} className="form-select">
                        <option value="">Select...</option>
                        {staff?.map((user, index) => (
                          <option key={index} value={user.userId}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="td-150">
                      <select key={expense.id} aria-labelledby="ExpCatAir" {...register(`transportationExpenses[${index}].subTypeCode`, { required: false })} className="form-select mb-3">
                        <option value="">Select...</option>
                        {lookups
                          .filter((lookup) => {
                            return lookup.lookupType === "StaffExpenseTransportation";
                          })
                          .map((lookup, index) => (
                            <option key={index} value={lookup.lookupCode}>
                              {lookup.lookupDescription}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`transportationExpenses[${index}].estimatedExpense`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            className="form-control"
                            aria-labelledby="ExpEstAir"
                            prefix={"$"}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value !== undefined ? value : 0}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => handleExpenseChange(`transportationExpenses[${index}].estimatedExpense`, e.value)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`transportationExpenses[${index}].actualExpense`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            className="form-control"
                            aria-labelledby="ExpActAir"
                            prefix={"$"}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => handleExpenseChange(`transportationExpenses[${index}].actualExpense`, e.value)}
                          />
                        )}
                      />
                    </td>
                    <td className={"padding-left-30 padding-top-25"}>
                      <a key={expense.id} hidden={getValues(`transportationExpenses`).length === 1} style={{ cursor: "pointer" }} onClick={() => removeTransportationExpense(index)}>
                        <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove"></i>
                      </a>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add More */}
      <div className="col-sm-12 mt-0 mb-5">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => appendTransportationExpense(defaultTransportationExpense)}>
          Add More
        </button>
      </div>
    </>
  );
};
