import React, { createContext, useState, useEffect, ReactNode } from "react";
import { BaseRecord } from "@refinedev/core";
import dataProvider from "@refinedev/simple-rest";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { LookupCode, LookupType } from "types/enums";

interface LookupsContextProps {
  lookups: BaseRecord[];
  getLookupDescription: (lookupCode: string) => string;
  getLookupDescriptions: (lookupCodes: string[]) => string[];
  getParentLookupCode: (childLookupCode: string) => LookupCode | undefined;
  getLookupCodesForTypeByParentCode: (lookupType: LookupType, parentLookupCode: LookupCode) => string[];
}

export const LookupsContext = createContext<LookupsContextProps>({
  lookups: [],
  getLookupDescription: () => "",
  getLookupDescriptions: () => [],
  getParentLookupCode: () => undefined,
  getLookupCodesForTypeByParentCode: () => [],
});

type LookupsProviderProps = {
  children?: ReactNode;
};

const LookupsProvider: React.FC<LookupsProviderProps> = ({ children }) => {
  const [lookups, setLookups] = useState<BaseRecord[]>([]);

  const setLookupsFromApi = async () => {
    const result = await dataProvider(API_URL, axiosInstance).getMany({ resource: "lookups", ids: [] });
    setLookups(result?.data ?? []);
  };

  useEffect(() => {
    setLookupsFromApi();
  }, []);

  const ensureLookups = (): boolean => {
    //If for any reason lookups failed to load at initial load, load them now
    if (lookups.length === 0) {
      //alert("Lookups failed to load at initial load. Please refresh the page.");
      console.log("LookupsContext.ensureLookups() -> Lookups failed to load at initial load. Reloading now.");
      setLookupsFromApi().then(() => {
        return true;
      });
    }
    return true;
  };

  const getLookupDescription = (lookupCode: string): string => {
    ensureLookups();
    let result: string = "";
    const lookupMatch = lookups.find((lookup) => lookup.lookupCode === lookupCode);
    if (lookupMatch) {
      result = lookupMatch.lookupDescription;
    }
    return result;
  };

  const getLookupDescriptions = (lookupCodes: string[]): string[] => {
    ensureLookups();
    const result: string[] = [];
    lookupCodes &&
      lookupCodes.forEach((code) => {
        const lookupMatch = lookups.find((lookup) => lookup.lookupCode === code);
        if (lookupMatch) {
          if (lookupMatch.lookupDescription !== "Other (please specify)") {
            result.push(lookupMatch.lookupDescription);
          } else {
            result.push("Other");
          }
        }
      });

    return result;
  };

  const getParentLookupCode = (childLookupCode: string): LookupCode | undefined => {
    ensureLookups();
    let result: LookupCode | undefined = undefined;
    const lookupMatch = lookups.find((lookup) => lookup.lookupCode === childLookupCode);
    if (lookupMatch) {
      result = lookupMatch.parentLookupCode;
    }
    return result;
  };

  const getLookupCodesForTypeByParentCode = (lookupType: LookupType, parentLookupCode: LookupCode): string[] => {
    ensureLookups();
    let result: LookupCode[] = [];
    lookupType &&
      parentLookupCode &&
      lookups.forEach((lookup) => {
        if (lookup.lookupType === lookupType && lookup.parentLookupCode === parentLookupCode) {
          result.push(lookup.lookupCode);
        }
      });

    return result;
  };

  return <LookupsContext.Provider value={{ lookups, getLookupDescription, getLookupDescriptions, getParentLookupCode, getLookupCodesForTypeByParentCode }}>{children}</LookupsContext.Provider>;
};

export default LookupsProvider;
