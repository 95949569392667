import React, { useState } from "react";
import { DialogClasses, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

type LogoutPromptProps = {
  showLogoutPrompt: boolean;
};

export const LogoutPrompt: React.FC<LogoutPromptProps> = ({ showLogoutPrompt }) => {
  return (
    <Dialog open={showLogoutPrompt}>
      <DialogTitle>
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Your Session Is About To Expire
          </h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <p>Are you still working? You will be logged out for inactivity shortly.</p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary mx-5 my-5 hsstillhere">
            I'm still working
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
