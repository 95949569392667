import React, { useContext, useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { LookupsContext } from "contexts/lookupsContext";
import { FocusArea } from "types/enums";

type FocusAreaCardProps = {
  register: UseFormRegister<BaseRecord>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
};

export const FocusAreaCard: React.FC<FocusAreaCardProps> = ({ register, setValue, getValues }) => {
  const [showFocusAreas_Assessment, setShowFocusAreas_Assessment] = useState<boolean>(false);
  const [showFocusAreas_Capacity, setShowFocusAreas_Capacity] = useState<boolean>(false);
  const [showFocusAreas_Planning, setShowFocusAreas_Planning] = useState<boolean>(false);
  const [showFocusAreas_Implementation, setShowFocusAreas_Implementation] = useState<boolean>(false);
  const [showFocusAreas_Evaluation, setShowFocusAreas_Evaluation] = useState<boolean>(false);

  const [showFocusAreas_Assessment_Other, setShowFocusAreas_Assessment_Other] = useState<boolean>(false);
  const [showFocusAreas_Capacity_Other, setShowFocusAreas_Capacity_Other] = useState<boolean>(false);
  const [showFocusAreas_Planning_Other, setShowFocusAreas_Planning_Other] = useState<boolean>(false);
  const [showFocusAreas_Implementation_Other, setShowFocusAreas_Implementation_Other] = useState<boolean>(false);
  const [showFocusAreas_Evaluation_Other, setShowFocusAreas_Evaluation_Other] = useState<boolean>(false);

  const { lookups } = useContext(LookupsContext);

  useEffect(() => {
    const focusAreas: string[] = getValues("focusArea.lookup_FocusAreas");
    focusAreas &&
      focusAreas.forEach((focusArea) => {
        switch (focusArea) {
          case FocusArea.Assessment:
            setShowFocusAreas_Assessment(true);
            break;
          case FocusArea.Capacity:
            setShowFocusAreas_Capacity(true);
            break;
          case FocusArea.Planning:
            setShowFocusAreas_Planning(true);
            break;
          case FocusArea.Implementation:
            setShowFocusAreas_Implementation(true);
            break;
          case FocusArea.Evaluation:
            setShowFocusAreas_Evaluation(true);
            break;
        }
      });

    const focusAreas_Assessment: string[] = getValues("focusArea.lookup_FocusAreas_Assessment");
    focusAreas_Assessment &&
      focusAreas_Assessment.forEach((focusArea) => {
        if (focusArea.toLowerCase().includes("other")) {
          setShowFocusAreas_Assessment_Other(true);
        }
      });
    const focusAreas_Capacity: string[] = getValues("focusArea.lookup_FocusAreas_Capacity");
    focusAreas_Capacity &&
      focusAreas_Capacity.forEach((focusArea) => {
        if (focusArea.toLowerCase().includes("other")) {
          setShowFocusAreas_Capacity_Other(true);
        }
      });
    const focusAreas_Planning: string[] = getValues("focusArea.lookup_FocusAreas_Planning");
    focusAreas_Planning &&
      focusAreas_Planning.forEach((focusArea) => {
        if (focusArea.toLowerCase().includes("other")) {
          setShowFocusAreas_Planning_Other(true);
        }
      });
    const focusAreas_Implementation: string[] = getValues("focusArea.lookup_FocusAreas_Implementation");
    focusAreas_Implementation &&
      focusAreas_Implementation.forEach((focusArea) => {
        if (focusArea.toLowerCase().includes("other")) {
          setShowFocusAreas_Implementation_Other(true);
        }
      });
    const focusAreas_Evaluation: string[] = getValues("focusArea.lookup_FocusAreas_Evaluation");
    focusAreas_Evaluation &&
      focusAreas_Evaluation.forEach((focusArea) => {
        if (focusArea.toLowerCase().includes("other")) {
          setShowFocusAreas_Evaluation_Other(true);
        }
      });
  }, []);

  const handleFocusAreaChange = (value: string, checked: boolean) => {
    //console.log("handleFocusAreaChange", value, checked);

    switch (value) {
      case FocusArea.Assessment:
        setShowFocusAreas_Assessment(checked);
        //clear out corresponding child fields if unchecked
        if (!checked) {
          setShowFocusAreas_Assessment_Other(false);
          setValue("focusArea.lookup_FocusAreas_Assessment", null);
          setValue("focusArea.assessment_Other", null);
        }
        break;
      case FocusArea.Capacity:
        setShowFocusAreas_Capacity(checked);
        //clear out corresponding child fields if unchecked
        if (!checked) {
          setShowFocusAreas_Capacity_Other(false);
          setValue("focusArea.lookup_FocusAreas_Capacity", null);
          setValue("focusArea.capacity_Other", null);
        }
        break;
      case FocusArea.Planning:
        setShowFocusAreas_Planning(checked);
        //clear out corresponding child fields if unchecked
        if (!checked) {
          setShowFocusAreas_Planning_Other(false);
          setValue("focusArea.lookup_FocusAreas_Planning", null);
          setValue("focusArea.planning_Other", null);
        }
        break;
      case FocusArea.Implementation:
        setShowFocusAreas_Implementation(checked);
        //clear out corresponding child fields if unchecked
        if (!checked) {
          setShowFocusAreas_Implementation_Other(false);
          setValue("focusArea.lookup_FocusAreas_Implementation", null);
          setValue("focusArea.implementation_Other", null);
        }
        break;
      case FocusArea.Evaluation:
        setShowFocusAreas_Evaluation(checked);
        //clear out corresponding child fields if unchecked
        if (!checked) {
          setShowFocusAreas_Evaluation_Other(false);
          setValue("focusArea.lookup_FocusAreas_Evaluation", null);
          setValue("focusArea.evaluation_Other", null);
        }
        break;
      default:
        break;
    }
  };

  const handleSubCategoryChange = (subCategory: FocusArea, fieldNameOther: string, value: string, checked: boolean) => {
    //console.log("handleSubCategoryChange", value, checked);

    if (value.toLowerCase().includes("other")) {
      //clear out corresponding other field if unchecked
      if (!checked) setValue(fieldNameOther, null);

      switch (subCategory) {
        case FocusArea.Assessment:
          setShowFocusAreas_Assessment_Other(checked);
          break;
        case FocusArea.Capacity:
          setShowFocusAreas_Capacity_Other(checked);
          break;
        case FocusArea.Planning:
          setShowFocusAreas_Planning_Other(checked);
          break;
        case FocusArea.Implementation:
          setShowFocusAreas_Implementation_Other(checked);
          break;
        case FocusArea.Evaluation:
          setShowFocusAreas_Evaluation_Other(checked);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {/* begin::Client details */}
      <div className="card card-flush pb-2">
        {/* begin::Card header */}
        <div className="card-header">
          <div className="card-title">
            <h2>Strategic Prevention Framework Focus Areas</h2>
          </div>
        </div>
        {/* end::Card header */}

        {/* begin::Card body */}
        <div className="card-body">
          <div className="d-flex flex-column gap-5 gap-md-7">
            <div className="row g-5">
              {/* begin::Inputs */}

              <div className="col-md-12">
                {/* begin::fieldset */}
                <fieldset>
                  <legend>Which of the following elements of the SPF does this activity represent?</legend>
                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas";
                    })
                    .map((lookup, index) => (
                      <React.Fragment key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={lookup.lookupCode}
                            id={lookup.lookupCode}
                            key={index}
                            {...register("focusArea.lookup_FocusAreas", {
                              required: false,
                              value: null,
                            })}
                            onChange={(e: any) => handleFocusAreaChange(e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                  {/* begin::Input */}
                  {/* <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Assessment" />
                    <label className="form-check-label" htmlFor="Assessment">
                      Assessment
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Capacity" />
                    <label className="form-check-label" htmlFor="Capacity">
                      Capacity
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Planning" />
                    <label className="form-check-label" htmlFor="Planning">
                      Planning
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Implementation" />
                    <label className="form-check-label" htmlFor="Implementation">
                      Implementation
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Evaluation" />
                    <label className="form-check-label" htmlFor="Evaluation">
                      Evaluation
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Cultural competence" />
                    <label className="form-check-label" htmlFor="Cultural competence">
                      Cultural competence
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="Sustainability" />
                    <label className="form-check-label" htmlFor="Sustainability">
                      Sustainability
                    </label>
                  </div> */}
                </fieldset>

                {/* end::Input */}
              </div>

              <div className="col-md-12" style={{ display: `${showFocusAreas_Assessment ? "initial" : "none"}` }}>
                <fieldset>
                  <legend>Which element(s) of assessment is represented by this request?</legend>

                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas_Assessment";
                    })
                    .map((lookup, index) => (
                      <React.Fragment key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={lookup.lookupCode}
                            id={lookup.lookupCode}
                            key={index}
                            {...register("focusArea.lookup_FocusAreas_Assessment", {
                              required: false,
                              value: null,
                            })}
                            onChange={(e: any) => handleSubCategoryChange(FocusArea.Assessment, "focusArea.assessment_Other", e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                </fieldset>
              </div>
              
              <div className={`col-sm-6 ${showFocusAreas_Assessment_Other === true ? "" : "sr-only"}`}>
                                <label htmlFor="spf_other_assessment" className="form-label">
                  Describe the "Other" element of assessment:
                </label>
                <div className="input-group">
                  <input
                    id="spf_other_assessment"
                    type="text"
                    className="form-control"
                    {...register("focusArea.assessment_Other", {
                      required: false,
                      value: null,
                    })}
                  />
                </div>
              </div>

              <div className="col-md-12" style={{ display: `${showFocusAreas_Capacity ? "initial" : "none"}` }}>
                <fieldset>
                  <legend>Which element(s) of capacity is represented by this request?</legend>

                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas_Capacity";
                    })
                    .map((lookup, index) => (
                      <React.Fragment key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={lookup.lookupCode}
                            id={lookup.lookupCode}
                            key={index}
                            {...register("focusArea.lookup_FocusAreas_Capacity", {
                              required: false,
                              value: null,
                            })}
                            onChange={(e: any) => handleSubCategoryChange(FocusArea.Capacity, "focusArea.capacity_Other", e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </label>
                        </div>
                     </React.Fragment>
                    ))}
                </fieldset>
              </div>
              <div className={`col-sm-6 ${showFocusAreas_Capacity_Other === true ? "" : "sr-only"}`}>
                <div className="input-group">
                <label htmlFor="spf_other_capacity" className="form-label">
                  Describe the "Other" element of capacity:
                  <input
                    id="spf_other_capacity"
                    type="text"
                    className="form-control"
                    {...register("focusArea.capacity_Other", {
                      required: false,
                      value: null,
                    })}
                  />
                  </label>
                </div>
              </div>

              <div className="col-md-12" style={{ display: `${showFocusAreas_Evaluation ? "initial" : "none"}` }}>
                <fieldset>
                  <legend>Which element(s) of evaluation is represented by this request?</legend>

                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas_Evaluation";
                    })
                    .map((lookup, index) => (
                      <React.Fragment key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={lookup.lookupCode}
                            id={lookup.lookupCode}
                            key={index}
                            {...register("focusArea.lookup_FocusAreas_Evaluation", {
                              required: false,
                              value: null,
                            })}
                            onChange={(e: any) => handleSubCategoryChange(FocusArea.Evaluation, "focusArea.evaluation_Other", e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                </fieldset>
              </div>
              <div className={`col-sm-6 ${showFocusAreas_Evaluation_Other === true ? "" : "sr-only"}`}>
               
                <div className="input-group">
                <label htmlFor="spf_other_evaluation" className="form-label">
                  Describe the "Other" element of evaluation:
                                  <input
                    id="spf_other_evaluation"
                    type="text"
                    className="form-control"
                    {...register("focusArea.evaluation_Other", {
                      required: false,
                      value: null,
                    })}
                  />
                  </label>
                </div>
              </div>

              <div className="col-md-12" style={{ display: `${showFocusAreas_Implementation ? "initial" : "none"}` }}>
                <fieldset>
                  <legend>Which element(s) of implementation is represented by this request?</legend>

                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas_Implementation";
                    })
                    .map((lookup, index) => (
                      <React.Fragment key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={lookup.lookupCode}
                            id={lookup.lookupCode}
                            key={index}
                            {...register("focusArea.lookup_FocusAreas_Implementation", {
                              required: false,
                              value: null,
                            })}
                            onChange={(e: any) => handleSubCategoryChange(FocusArea.Implementation, "focusArea.implementation_Other", e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                </fieldset>
              </div>
              <div className={`col-md-6 ${showFocusAreas_Implementation_Other === true ? "" : "sr-only"}`}>
                <div className="input-group">
                <label htmlFor="spf_other_implementation" className="form-label">
                  Describe the "Other" element of implementation:
                                  <input
                    id="spf_other_implementation"
                    type="text"
                    className="form-control"
                    {...register("focusArea.implementation_Other", {
                      required: false,
                      value: null,
                    })}
                  />
                  </label>
                </div>
              </div>

              <div className="col-md-12" style={{ display: `${showFocusAreas_Planning ? "initial" : "none"}` }}>
                <fieldset>
                  <legend>Which element(s) of planning is represented by this request?</legend>

                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas_Planning";
                    })
                    .map((lookup, index) => (
                      <React.Fragment key={index}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={lookup.lookupCode}
                            id={lookup.lookupCode}
                            key={index}
                            {...register("focusArea.lookup_FocusAreas_Planning", {
                              required: false,
                              value: null,
                            })}
                            onChange={(e: any) => handleSubCategoryChange(FocusArea.Planning, "focusArea.planning_Other", e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={lookup.lookupCode}>
                            {lookup.lookupDescription}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                </fieldset>
              </div>
              <div className={`col-sm-6 ${showFocusAreas_Planning_Other === true ? "" : "sr-only"}`}>
                <div className="input-group">
                <label htmlFor="spf_other_planning" className="form-label">
                  Describe the "Other" element of planning:
                  <input
                    id="spf_other_planning"
                    type="text"
                    className="form-control"
                    {...register("focusArea.planning_Other", {
                      required: false,
                      value: null,
                    })}
                  />
                  </label>
                </div>
              </div>
            </div>
            {/* end::Row */}
          </div>
          {/* end::Service Request Dates */}
        </div>
        {/* end::Card body */}
      </div>
      {/* end::Client details */}
    </>
  );
};
