import React, { useEffect, useState, ChangeEvent } from "react";
import { Box, CircularProgress } from "@mui/material";
import { API_URL } from "App";

import { axiosInstance } from "index";

type EvalImportWizardProps = {
  activityId: number | undefined;
};

export const EvalImportWizard: React.FC<EvalImportWizardProps> = ({ activityId }) => {
  const [currentStep, setCurrentstep] = useState<number>(0);

  const [surveyId, setSurveyId] = useState<string>("");
  const [numEvaluations, setNumEvaluations] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (activityId !== undefined) {
      getEvaluations();
    }
  }, [activityId]);

  const onTryImport = async () => {
    if (currentStep < 1) {
      setCurrentstep(currentStep + 1);

      try {
        setIsLoading(true);

        const dta = {
          activityId: activityId,
          surveyId: surveyId,
          typeCode: "QAULTRICS",
        };

        const response = await axiosInstance.post(`${API_URL}/activities/${activityId}/evaluations`, dta);

        if (response != null && response.data != null) {
          if (response.data.statusCode === "A") {
            setNumEvaluations(response.data.numRows);
          } else if (response.data.statusCode === "E") {
            setIsError(true);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        console.error(error); // TODO: Handle error
      }

      //surveyimportrequest json object {typeCode, qualtricsSurveyId, activityId} /activities/{activityId}/evaluations/
    }
  };

  const getEvaluations = async () => {
    try {
      setIsLoading(true);

      const dta = {
        activityId: activityId,
      };

      const response = await axiosInstance.get(`${API_URL}/activities/${activityId}/evaluations`);

      if (response.data.evals === null || response.data.evals.length <= 0) {
        setCurrentstep(0);
      } else {
        setNumEvaluations(response.data.evals.length);

        if (response.data.evals.length > 0) {
          setCurrentstep(1);
        }

        if (response.data.surveyId != null) {
          setSurveyId(response.data.surveyId);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      console.error(error); // TODO: Handle error
    }
  };

  const deleteEvaluations = async () => {
    try {
      setIsLoading(true);

      const dta = {
        activityId: activityId,
      };

      const response = await axiosInstance.delete(`${API_URL}/activities/${activityId}/evaluations`);

      if (response.data === true) {
        setNumEvaluations(0);
        setCurrentstep(0);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error); // TODO: Handle error
    }
  };

  const onBack = () => {
    if (currentStep > 0) {
      setCurrentstep(currentStep - 1);
      setIsError(false);
    }
  };
  const onUpdateSurveyId = (event: ChangeEvent<HTMLInputElement>) => {
    setSurveyId(event.target.value);
  };

  if (isLoading === true) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  let hsWizardMessage = null;

  if (isError === true) {
    return (
      <div>
        <h3 className="h4 mb-5">There was an error trying to import Qualtrics Evaluations.</h3>
        <p className="fs-6">
          <i>Please go back and try again.</i>
        </p>
        <p>
          <button type="button" className="btn btn-sm btn-warning" onClick={onBack}>
            Go Back
          </button>
        </p>
      </div>
    );
  } else if (currentStep > 0) {
    hsWizardMessage = (
      <div>
        <h3 className="h4 mb-5">You have successfully connected to Qualtrics and imported evaluations!</h3>
        <p className="fs-6">
          The SPTAC Dashboard will pull in the most recent Evaluations entered into Qualtrics on a daily basis. Currently, there are {numEvaluations} Evaluations imported for the Survey ID {surveyId}.
        </p>
        <p className="fs-6">
          <i>To remove all evaluations for this event, please click the "Remove Evaluations" button below.</i>
        </p>
        <p>
          <button className="btn btn-sm btn-danger" onClick={deleteEvaluations}>
            Remove Evaluations
          </button>
        </p>
      </div>
    );
  } else {
    hsWizardMessage = (
      <div>
        <h3 className="h4 mb-5">Please enter your Qualtrics Survey ID below to import evaluations for this activity.</h3>
        <p className="fs-6">The SPTAC Dashboard will pull in the most recent Evaluations entered into Qualtrics on a daily basis.</p>

        <label htmlFor="qualtricsSurveyId" className="form-label">
          Qualtrics Survey ID
        </label>
        <input
          type="text"
          className="form-control w-50"
          id="qualtricsSurveyId"
          value={surveyId}
          onChange={(event) => {
            onUpdateSurveyId(event);
          }}
        />
        <button type="button" className="btn btn-sm btn-primary mt-5" onClick={onTryImport}>
          Import Evaluations
        </button>
      </div>
    );
  }

  return <>{hsWizardMessage}</>;
};
