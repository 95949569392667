import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { BaseRecord } from "@refinedev/core/dist/interfaces";

import { LookupsContext } from "contexts/lookupsContext";
import { RecordStatus } from "types/enums";

interface ExpenseTotalsStaff {
  userId: string;
  fullName: string;
  estimatedDays: number;
  estimatedTotal: number;
  actualTotal: number;
  expenseCategory: string;
  estimatedMiles: number;

  actualMiles: number;
}

interface HourlyStaffExpenseViewProps {
  data: BaseRecord | undefined;
  activityData?: BaseRecord[] | undefined;
}

export const GeneralStaffExpensesView: React.FC<HourlyStaffExpenseViewProps> = ({ data, activityData }) => {
  //const [expenseTotalsByStaffUserName, setExpenseTotalsByStaffUserName] = useState<ExpenseTotalsByStaffUserName>({});
  const [totalEstimatedExpenses, setTotalEstimatedExpenses] = useState(0);
  const [totalActualExpenses, setTotalActualExpenses] = useState<any>();
  const [dailyExpenseTotals, setDailyExpenseTotal] = useState<ExpenseTotalsStaff[]>([]);
  const [mileageExpenses, setMileageExpenses] = useState<ExpenseTotalsStaff[]>([]);
  const [transportationExpenses, setTransportationExpenses] = useState<ExpenseTotalsStaff[]>([]);
  const [userTotalsExpenses, setUserTotalsExpenses] = useState<ExpenseTotalsStaff[]>([]);
  const [actualMaterialsCost, setActualMaterialsCost] = useState(0);

  const [estimatedMaterialsCost, setEstimatedMaterialsCost] = useState(0);
  const { getLookupDescription } = useContext(LookupsContext);

  useEffect(() => {
    if (data != null) setExpenseTotals(data);

    
  }, [data]);

  const setExpenseTotals = (data: BaseRecord | undefined) => {
    if (data === null) return;

    let tmpTotalEstimatedExpenses = 0;
    let tmpTotalActualExpenses = 0;
    let tmpTotalUserExpenses: ExpenseTotalsStaff[] = [];

    // Daily Expenses
    let tmpDailyExpenseTotals: ExpenseTotalsStaff[] = [];

    data?.dailyExpenses?.forEach((expense: any) => {
      let acc: any = null;

      let proceed: boolean = false;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
        if (
          acc != null &&
          acc.approvalWorkflowState != null &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn
        ) {
          proceed = true;
        }
      }

      if (acc == null || proceed === true) {
        let existingItemIndex = tmpDailyExpenseTotals.findIndex((item) => item.userId === expense.staffUserId && item.expenseCategory === expense.subTypeCode);

        if (existingItemIndex > -1) {
          tmpDailyExpenseTotals[existingItemIndex].estimatedTotal = tmpDailyExpenseTotals[existingItemIndex].estimatedTotal + expense.estimatedTotal;
          tmpDailyExpenseTotals[existingItemIndex].actualTotal += expense.actualTotal;
          tmpDailyExpenseTotals[existingItemIndex].estimatedDays += expense.estimatedDays;
          tmpDailyExpenseTotals[existingItemIndex].fullName = expense.staffUserName;
          tmpDailyExpenseTotals[existingItemIndex].estimatedMiles = expense.estimatedMiles;
          tmpDailyExpenseTotals[existingItemIndex].actualMiles = expense.actualMiles;
        } else {
          const newItem = {
            userId: expense.staffUserId,
            estimatedTotal: expense.estimatedTotal,
            actualTotal: expense.actualTotal,
            estimatedDays: expense.estimatedDays,
            fullName: expense.staffUserName,
            expenseCategory: expense.subTypeCode,
            estimatedMiles: expense.estimatedMiles,
            actualMiles: expense.actualMiles,
          };
          tmpDailyExpenseTotals.push(newItem);
        }

     

        tmpTotalEstimatedExpenses = tmpTotalEstimatedExpenses + expense.estimatedTotal;
        tmpTotalActualExpenses = tmpTotalActualExpenses + expense.actualTotal ?? 0;

        let existingUserTotalIndex = tmpTotalUserExpenses.findIndex((item) => item.userId === expense.staffUserId);
        if (existingUserTotalIndex > -1) {
          tmpTotalUserExpenses[existingUserTotalIndex].estimatedTotal = tmpTotalUserExpenses[existingUserTotalIndex].estimatedTotal + expense.estimatedTotal;
          tmpTotalUserExpenses[existingUserTotalIndex].actualTotal = tmpTotalUserExpenses[existingUserTotalIndex].actualTotal + expense.actualTotal;
          tmpTotalUserExpenses[existingUserTotalIndex].actualMiles = expense.actualMiles;
        } else {
          const newItem = {
            userId: expense.staffUserId,
            estimatedTotal: expense.estimatedTotal,
            actualTotal: expense.actualTotal,
            estimatedDays: 0,
            fullName: expense.staffUserName,
            expenseCategory: "",
            estimatedMiles: expense.estimatedMiles,
            actualMiles: expense.actualMiles,
          };
          tmpTotalUserExpenses.push(newItem);
        }
      }
    });

    setDailyExpenseTotal(tmpDailyExpenseTotals);

    let tmpTransportationExpenses: ExpenseTotalsStaff[] = [];

    // Transportation Expenses
    data?.transportationExpenses?.forEach((expense: any) => {
      let acc: any = null;

      let proceed: boolean = false;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
        if (
          acc != null &&
          acc.approvalWorkflowState != null &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn
        ) {
          proceed = true;
        }
      }

      if (acc == null || proceed === true) {
        let existingItemIndex = tmpTransportationExpenses.findIndex((item) => item.userId === expense.staffUserId && item.expenseCategory === expense.subTypeCode);
        
        if (existingItemIndex > -1) {
          tmpTransportationExpenses[existingItemIndex].estimatedTotal = tmpTransportationExpenses[existingItemIndex].estimatedTotal + expense.estimatedTotal;
          tmpTransportationExpenses[existingItemIndex].actualTotal += expense.actualTotal;
          //tmpTransportationExpenses[existingItemIndex].estimatedDays += expense.estimatedDays;
          tmpTransportationExpenses[existingItemIndex].fullName = expense.staffUserName;
          tmpTransportationExpenses[existingItemIndex].actualMiles = expense.actualMiles;
        } else {
          const newItem = {
            userId: expense.staffUserId,
            estimatedTotal: expense.estimatedTotal,
            actualTotal: expense.actualTotal,
            estimatedDays: expense.estimatedDays,
            fullName: expense.staffUserName,
            expenseCategory: expense.subTypeCode,
            estimatedMiles: expense.estimatedMiles,
            actualMiles: expense.actualMiles,
          };
          tmpTransportationExpenses.push(newItem);
        }

        let existingUserTotalIndex = tmpTotalUserExpenses.findIndex((item) => item.userId === expense.staffUserId);

        if (existingUserTotalIndex > -1) {
          tmpTotalUserExpenses[existingUserTotalIndex].estimatedTotal = tmpTotalUserExpenses[existingUserTotalIndex].estimatedTotal + expense.estimatedTotal;

          tmpTotalUserExpenses[existingUserTotalIndex].actualTotal = tmpTotalUserExpenses[existingUserTotalIndex].actualTotal + expense.actualTotal;
        } else {
          const newItem = {
            userId: expense.staffUserId,
            estimatedTotal: expense.estimatedTotal ?? 0,
            actualTotal: expense.actualTotal,
            estimatedDays: 0,
            fullName: expense.staffUserName,
            expenseCategory: "",
            estimatedMiles: expense.estimatedMiles,
            actualMiles: expense.actualMiles,
          };
          tmpTotalUserExpenses.push(newItem);
        }

        tmpTotalEstimatedExpenses = tmpTotalEstimatedExpenses + expense.estimatedTotal;
        tmpTotalActualExpenses = tmpTotalActualExpenses + expense.actualTotal;
      }
    });

    setTransportationExpenses(tmpTransportationExpenses);

    let tmpMileageExpenseTotals: ExpenseTotalsStaff[] = [];

    // Mileage Expenses
    data?.mileageExpenses?.forEach((expense: any) => {
      let acc: any = null;

      let proceed: boolean = false;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
        if (
          acc != null &&
          acc.approvalWorkflowState != null &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn
        ) {
          proceed = true;
        }
      }

      if (acc == null || proceed === true) {
        let existingItemIndex = tmpMileageExpenseTotals.findIndex((item) => item.userId === expense.staffUserId && item.expenseCategory === expense.subTypeCode);

        if (existingItemIndex > -1) {
          tmpMileageExpenseTotals[existingItemIndex].estimatedTotal = tmpMileageExpenseTotals[existingItemIndex].estimatedTotal + expense.estimatedTotal;
          tmpMileageExpenseTotals[existingItemIndex].actualTotal += expense.actualTotal;
          tmpMileageExpenseTotals[existingItemIndex].estimatedDays += expense.estimatedDays;
          tmpMileageExpenseTotals[existingItemIndex].fullName = expense.staffUserName;
          tmpMileageExpenseTotals[existingItemIndex].estimatedMiles += expense.estimatedMiles;
          tmpMileageExpenseTotals[existingItemIndex].actualMiles += expense.actualMiles;
        } else {
          const newItem = {
            userId: expense.staffUserId,
            estimatedTotal: expense.estimatedTotal,
            actualTotal: expense.actualTotal,
            estimatedDays: expense.estimatedDays,
            fullName: expense.staffUserName,
            expenseCategory: expense.subTypeCode,
            estimatedMiles: expense.estimatedMiles,
            actualMiles: expense.actualMiles,
          };
          tmpMileageExpenseTotals.push(newItem);
        }
        let existingUserTotalIndex = tmpTotalUserExpenses.findIndex((item) => item.userId === expense.staffUserId);
        if (existingUserTotalIndex > -1) {
          tmpTotalUserExpenses[existingUserTotalIndex].estimatedTotal = tmpTotalUserExpenses[existingUserTotalIndex].estimatedTotal + expense.estimatedTotal;
          tmpTotalUserExpenses[existingUserTotalIndex].actualTotal = tmpTotalUserExpenses[existingUserTotalIndex].actualTotal + expense.actualTotal;
          tmpTotalUserExpenses[existingUserTotalIndex].estimatedMiles += expense.estimatedMiles;
          tmpTotalUserExpenses[existingUserTotalIndex].actualMiles += expense.actualMiles;
        } else {
          const newItem = {
            userId: expense.staffUserId,
            estimatedTotal: expense.estimatedTotal,
            actualTotal: expense.actualTotal,
            estimatedDays: 0,
            fullName: expense.staffUserName,
            expenseCategory: "",
            estimatedMiles: expense.estimatedMiles,
            actualMiles: expense.actualMiles,
          };
          tmpTotalUserExpenses.push(newItem);
        }

        tmpTotalEstimatedExpenses = tmpTotalEstimatedExpenses + expense.estimatedTotal;
        tmpTotalActualExpenses = tmpTotalActualExpenses + expense.actualTotal;
      }
    });






    if (activityData == null)
      {
        setActualMaterialsCost(data?.materialsCost);
        setEstimatedMaterialsCost(data?.estimatedMaterialsCost);
      }
      else
      {



        console.log("here10", activityData);

        let tmpActualMatericalCost = 0;
        let tmpEstimatedMaterialsCost = 0;
        activityData?.forEach((acc) => {

          if (
            acc != null &&
            acc.approvalWorkflowState != null &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn
          ) {
            

          if (acc?.materialsCost != null)
            {
              tmpActualMatericalCost = tmpActualMatericalCost + acc?.materialsCost;
            }


            

            if (acc?.estimatedMaterialsCost != null)
              {
                tmpEstimatedMaterialsCost = tmpEstimatedMaterialsCost + acc?.estimatedMaterialsCost;
              }
            }

        });
       
        setActualMaterialsCost(tmpActualMatericalCost);
                setEstimatedMaterialsCost(tmpEstimatedMaterialsCost);
      }


    setMileageExpenses(tmpMileageExpenseTotals);

    //setExpenseTotalsByStaffUserName(expenseTotalsByStaffUserName);
    //sum totals for all expenses (non-hourly) for this activity

    setUserTotalsExpenses(tmpTotalUserExpenses);

    setTotalEstimatedExpenses(tmpTotalEstimatedExpenses);
    setTotalActualExpenses(tmpTotalActualExpenses);
  };

  return (
    <>
      <div className="tab-pane" id="expenses" role="tabpanel" aria-labelledby="tab-3">
        {dailyExpenseTotals != null && dailyExpenseTotals.length > 0 && (
          <>
            <h3 className="h4 mb-3 py-2 text-bg-light">Lodging, Per Diem & Rental Space</h3>

            <table className="table hs-table-xs">
              <thead>
                <tr className="fw-bold">
                  <th scope="col" className="w-20">
                    Staff Member
                  </th>
                  <th scope="col" className="w-20">
                    Expense Category
                  </th>
                  <th scope="col" className="w-20">
                    Estimated Total
                  </th>
                  <th scope="col">Actual Total</th>
                </tr>
              </thead>
              <tbody>
                
                
                {dailyExpenseTotals.sort((a: any, b: any) => a.fullName.localeCompare(b.fullName)).map((expense: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{expense.fullName}</td>
                      <td>{getLookupDescription(expense.expenseCategory)}</td>

                      <td>{Number(expense.estimatedTotal != null ? expense.estimatedTotal : 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                      <td>{Number(expense.actualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}

        {transportationExpenses != null && transportationExpenses.length > 0 && (
          <>
            <h3
              className={`h4 
            ${dailyExpenseTotals != null && dailyExpenseTotals.length > 0 ? "" : ""} 
            mb-3 py-2 text-bg-light`}
            >
              Airfare, Ground, Other
            </h3>
            <table className="table hs-table-xs">
              <thead>
                <tr className="fw-bold mb-2">
                  <th scope="col" className="w-20">
                    Staff Member
                  </th>
                  <th scope="col" className="w-20">
                    Expense Category
                  </th>
                  <th scope="col" className="w-20">
                    Estimated Total
                  </th>
                  <th scope="col">Actual Total</th>
                </tr>
              </thead>
              <tbody>
                {transportationExpenses.sort((a: any, b: any) => a.fullName.localeCompare(b.fullName)).map((expense: any, index: number) => (
                  <tr key={index}>
                    <td>{expense.fullName}</td>
                    <td>{getLookupDescription(expense.expenseCategory)}</td>
                    <td>{Number(expense.estimatedTotal != null ? expense.estimatedTotal : 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                    <td>{Number(expense.actualTotal != null ? expense.actualTotal : 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {mileageExpenses != null && mileageExpenses.length > 0 && (
          <>
            <h3
              className={`h4 
            ${(dailyExpenseTotals != null && dailyExpenseTotals.length > 0) || (transportationExpenses != null && transportationExpenses.length > 0) ? "" : ""} 
            mb-3 py-2 text-bg-light`}
            >
              Mileage
            </h3>
            <table className="table hs-table-xs">
              <thead>
                <tr className="fw-bold">
                  <th scope="col" className="w-20">Staff Member</th>
                  <th scope="col" className="w-20">Estimated Total # of Miles</th>
                  <th scope="col" className="w-20">Estimated Total</th>
                  <th scope="col" className="w-20">Actual Total # of Miles</th>
                  <th scope="col">Actual Total</th>
                </tr>
              </thead>
              <tbody>
                {mileageExpenses.sort((a: any, b: any) => a.fullName.localeCompare(b.fullName)).map((expense: any, index: number) => (
                  <tr key={index}>
                    <td>{expense.fullName}</td>
                    <td>{expense.estimatedMiles}</td>
                    <td>{Number(expense.estimatedTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                    <td>{expense.actualMiles}</td>
                    <td>{Number(expense.actualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <h3 className="h4 mb-3 py-2 text-bg-light">Expense Totals</h3>
        
        <div className="col-sm-12">
          {/* begin::Inputs */}
          <table className="table hs-table-xs">
            <caption className="sr-only text-black">Expense Totals for Staff Members</caption>
            <thead className="">
              <tr className="fw-bold">
                <th scope="col" className="w-20">
                  Staff Member
                </th>
                <th scope="col" className="w-20">Estimated Expenses</th>
                <th scope="col" className="">Actual Expenses</th>
              </tr>
            </thead>
            <tbody>
              {userTotalsExpenses.sort((a: any, b: any) => a.fullName.localeCompare(b.fullName)).map((expense: any, index: number) => (
                <tr key={index}>
                  <th scope="row">{expense.fullName}</th>
                  <td>{Number(expense.estimatedTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                  <td>{Number(expense.actualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="table-footer-divider">
              <tr className="fw-bold">
                <th scope="row">Travel Totals                  </th>
                                <td>{Number(totalEstimatedExpenses).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                <td>{Number(totalActualExpenses).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
              </tr>
              <tr className="fw-bold generalstaffexpensematerialscostrow">
                <th scope="row" className="pt-0">Material Totals</th>
                <td className="pt-0">{Number(estimatedMaterialsCost).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                <td className="pt-0">{Number(actualMaterialsCost).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
              </tr>
              <tr className="fw-bold">
                <th scope="row">Totals</th>
                <td>{Number(totalEstimatedExpenses + estimatedMaterialsCost).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
                <td>{Number(totalActualExpenses + actualMaterialsCost).toLocaleString("en-US", { style: "currency", currency: "USD" })}</td>
              </tr>
            </tfoot>
          </table>
          {/* end::Input */}
        </div>
      </div>
    </>
  );
};
