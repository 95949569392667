import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/brands.css";
import "@fortawesome/fontawesome-free/css/solid.css";

import React from "react";
import { createRoot } from "react-dom/client";
import axios, { AxiosRequestConfig } from "axios";
import App, { AUTH_TOKEN_KEY, AUTH_LOGGED_IN_USER_ID_KEY, API_URL, API_GRAPH_URL } from "./App";
import reportWebVitals from "./reportWebVitals";
import dataProvider from "@refinedev/simple-rest";

import { EventType, PublicClientApplication, AccountInfo, EventPayload, SilentRequest } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, tokenRequest, msGraphTokenRequest } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);


msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    //console.log("msal instance login success callback...");

    const payload: EventPayload = event.payload;
    msalInstance.setActiveAccount(payload as AccountInfo);

    let account = msalInstance.getActiveAccount();

    const request: SilentRequest = {
      ...tokenRequest,
      account: account!,
    };

    try {
      // Silently acquires an access token which is then attached to a request for API access
      const response = await msalInstance.acquireTokenSilent(request);

      localStorage.setItem(AUTH_TOKEN_KEY, response.accessToken);
      localStorage.setItem(AUTH_LOGGED_IN_USER_ID_KEY, response.uniqueId);
    } catch (e) {
      msalInstance.acquireTokenPopup(request).then((response) => {
        localStorage.setItem(AUTH_TOKEN_KEY, response.accessToken);
        localStorage.setItem(AUTH_LOGGED_IN_USER_ID_KEY, response.uniqueId);
      });
    }

    //Post Login event to API
    const roles = account?.idTokenClaims?.roles ?? [];
    try {
      //console.log("Graph API result: ", userProfile);

      //console.log("Posting login event to API");
      await dataProvider(API_URL, axiosInstance).create({
        resource: "users/login",
        variables: {
          userId: account?.localAccountId, //account?.localAccountId,
        },
      });
    } catch (ex) {
      //TODO: somehow invalidate login/account so Authbindings: check() will return false
      console.error(ex);
    }
  }
});

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  // Here we can perform any function we'd like on the request
  async (request: AxiosRequestConfig) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...tokenRequest,
      account: account,
    });

    let accessToken = msalResponse.accessToken;

    if (request.url && request.url.startsWith(API_GRAPH_URL)) {
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...msGraphTokenRequest,
        account: account,
      });
      accessToken = msalResponse.accessToken;
    }

    // Check if the header property exists
    if (request.headers) {
      // Set the Authorization header if it exists
      request.headers["Authorization"] = `Bearer ${accessToken}`;
    } else {
      // Create the headers property if it does not exist
      request.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    //override any HttpPatch attempt with HttpPut
    if (request.method && request.method === "patch") {
      request.method = "put";
    }
    return request;
  }
);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
