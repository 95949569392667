//DateTime Conversion/Formatting
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

type CommentsProps = {
  comments: any[];
};

export const Comments: React.FC<CommentsProps> = ({ comments }) => {
  if (comments === undefined) {
    return <></>;
  }

  const commentsSorted = [...comments];

  return (
    <>
      {/* begin::Timeline */}
      <div className="timeline">
        {/* begin::Timeline items */}

        {/* TODO: apply a sort on the array based on the comment timestamp (possible take in a sort arg to this prop as well) */}
        {commentsSorted?.reverse().map((comment: any, index: any) => (
          <div className="timeline-item" key={index}>
            {/* begin::Timeline line */}
            <div className="timeline-line w-40px"></div>
            {/* end::Timeline line */}

            {/* begin::Timeline icon */}
            <div className="timeline-icon symbol symbol-circle symbol-40px">
              <div className="symbol-label bg-primary">
                <i className="fa-regular fa-comment" style={{ color: "#fff" }}></i>
              </div>
            </div>
            {/* end::Timeline icon */}

            {/* begin::Timeline content */}
            <div className="timeline-content mb-10 mt-n1">
              <div className="pe-3 mb-5">
                {/* begin::Comment */}
                <div className=" mb-2">{comment.commentText}</div>
                {/* end::Comment */}

                {/* begin::User Date */}
                <div className="d-flex align-items-center mt-1 fs-6">
                  <div className="fw-semibold me-2 fs-7">
                    By {comment.createUserName} on {comment?.createDate && dayjs.utc(comment.createDate).tz("America/New_York").format("MMMM DD, YYYY") + " at " + dayjs.utc(comment.createDate).tz("America/New_York").format("hh:mm A") + " EST"}
                  </div>
                </div>
                {/* end::User Date */}
              </div>
            </div>
            {/* end::Timeline content */}
          </div>
        ))}

        {/* end::Timeline items */}
      </div>
      {/* end::Timeline */}
    </>
  );
};
