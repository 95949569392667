import React, { useEffect } from "react";
import { UseFormRegister, Control, useFieldArray, Controller, UseFormSetValue } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { NumericFormat } from "react-number-format";

//Material Design UI + dependencies
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

type StaffRateProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
  setValue: UseFormSetValue<BaseRecord>;
};

export const StaffRate: React.FC<StaffRateProps> = ({ register, control, setValue }) => {
  const defaultStaffRate = { rateUsd: null, rateAsOfDate: null };

  const {
    fields: staffRateFields,
    append: appendStaffRate,
    remove: removeStaffRate,
  } = useFieldArray({
    control,
    name: "staffRates",
  });

  useEffect(() => {
    control?._formValues?.staffRates?.length === 0 && appendStaffRate(defaultStaffRate);
  }, []);

  const handleRateChange = (field: string, value: string) => {
    // Set the formatted value to the form data
    setValue(field, parseFloat(value.replace(/[^0-9.-]/g, "")));
  };

  return (
    <>
      {staffRateFields.map((staffRate, index) => (
        <React.Fragment key={index}>
          <div className="d-flex flex-column gap-5 gap-md-7">
            <div className="row g-5">
              <div className="col-sm-6">
                <label key={staffRate.id} htmlFor={`${index}_rateusd`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
                  Hourly Rates
                </label>

                <div className={`input-group mb-5 ${staffRateFields.length === 1 ? "mt-2" : ""}`}>
                  <Controller
                    key={staffRate.id}
                    name={`staffRates[${index}].rateUsd`}
                    control={control}
                    render={({ field: { onChange, value }, ...props }) => (
                      <NumericFormat
                        className="form-control"
                        thousandSeparator={true} // Add thousand separator (e.g., 1,000)
                        decimalScale={2} // Number of decimal places
                        fixedDecimalScale={true} // Fixed number of decimal places
                        prefix={"$"} // Currency symbol or prefix
                        allowNegative={false} // Disable negative values
                        defaultValue={null}
                        value={value}
                        allowLeadingZeros
                        id={`${index}_rateusd`}
                        onValueChange={(e: any) => handleRateChange(`staffRates[${index}].rateUsd`, e.value)} // Handle value change
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                {/* begin::fieldset */}
                <div>
                  <label key={staffRate.id} htmlFor={`${index}_asofdate`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
                    As of Date
                  </label>

                  <div className="">
                    <div className={`input-group ${staffRateFields.length === 1 ? "mt-2" : ""}`}>
                      <Controller
                        key={staffRate.id}
                        name={`staffRates[${index}].rateAsOfDate`}
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value }, ...props }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={onChange} className="form-control staffRateDatePicker" value={value ? dayjs(value) : null}
                            slotProps={{
                              textField: {
                                id: `${index}_asofdate`
                              }
                              
                            }} />
                          </LocalizationProvider>
                        )}
                      />
                      <div className="form-check form-check-inline mt-1" style={{ paddingTop: "1%" }}>
                        <a key={staffRate.id} hidden={staffRateFields.length === 1} style={{ cursor: "pointer" }} onClick={() => removeStaffRate(index)}>
                          <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove"></i>
                        </a>
                      </div>
                    </div>
                    {/* end::row */}
                  </div>
                  {/* end::fieldset */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}

      <div className="col-sm-12">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => appendStaffRate(defaultStaffRate)}>
          Add New Rate
        </button>
      </div>
    </>
  );
};
