import React, { useState, useEffect } from "react";

//Local Components

import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { ServiceRequestCreateUpdate } from "components";

export const ServiceRequestCreatePage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);

  useEffect(() => {
    setBreadCrumbsTitle("Service Request");
    setBreads([
      { label: "Service Requests", path: "/servicerequests" },
      { label: "New Service Request", path: "" },
    ]);
  }, []);

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ServiceRequestCreateUpdate />
        </div>
      </main>
    </>
  );
};
