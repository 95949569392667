import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IBreadcrumb } from "interfaces";

import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { Box } from "@mui/material";

interface BreadcrumbsProps {
  breadcrumbsTitle: string;
  breadcrumbs: IBreadcrumb[];
  onFilterClicked?: () => void;
  onExpenseClicked?: () => void;
  showAddServiceRequest?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbsTitle, breadcrumbs, onFilterClicked, onExpenseClicked, showAddServiceRequest }) => {
  //console.log("here130", onFilterClicked);

  const { userPermissions } = useContext(LoggedInUserContext);

  let filterBtn = null;
  const onFilterClick = () => {
    if (onFilterClicked != null) {
      onFilterClicked();
    }
  };

  if (onFilterClicked != null) {
    filterBtn = (
      <button className="btn btn-sm btn-primary" onClick={onFilterClick}>
        <i className="fa-solid fa-filter mb-1"></i>Filter
      </button>
    );
  }

  let expenseBtn = null;
  const onExpenseClick = () => {
    if (userPermissions.canViewExpenses === true) {
      if (onExpenseClicked != null) {
        onExpenseClicked();
      }
    }
  };

  if (onExpenseClicked != null) {
    if (userPermissions.canViewExpenses === true) {
      expenseBtn = (
        <button className="btn btn-sm btn-secondary me-2" type="button" data-bs-toggle="collapse" onClick={onExpenseClick} data-bs-target="#collapseExpenses" aria-expanded="false" aria-controls="collapseExpenses">
          View Expenses
        </button>
      );
    }
  }

  let serviceRequestBtn = null;

  if (showAddServiceRequest != null && showAddServiceRequest == true && userPermissions.canCreate == true) {
    serviceRequestBtn = (
      <a href="/servicerequests/create" className="btn btn-sm btn-secondary me-2">
      <i className="fa-solid fa-plus mb-1"></i>
        Add Service Request&nbsp;
      </a>
    );
  }

  return (
    <>
      {/* begin::Page Title & Breadcrumbs */}
      <nav aria-label="Breadcrumbs" id="app_toolbar" className="app-toolbar py-3 py-lg-6 d-print-none">
        {/* begin::Page Title & Breadcrumbs container */}
        <div id="app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
          {/* begin::Page title */}
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            {/* begin::Title */}
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{breadcrumbsTitle}</h1>
            {/* end::Title */}
            {/* begin::Breadcrumb */}
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              {/*  */}
              {breadcrumbs.map((el, index) => {
                let label = "";
                let path = "";

                
                if (el != null && el.label != "undefined undefined" && el.label != null && el.label.includes("undefined") === false) {
                  label = el.label;
                  path = el.path;
                }

                return (
                  
                  <React.Fragment key={index}>
                    {/* begin::Item */}
                    <li className="breadcrumb-item text-muted">
                      {index === breadcrumbs.length - 1 ? (
                        breadcrumbs.length === 1 ? (
                          <>
                            <Link to={path ?? ""} className="text-muted text-hover-primary">
                              {label}
                            </Link>
                          </>
                        ) : (
                          <>{label}</>
                        )
                      ) : (
                        <Link to={path ?? ""} className="text-muted text-hover-primary">
                          {label}
                        </Link>
                      )}
                    </li>
                    {/* add separator or not */}
                    {index === breadcrumbs.length - 1 ? null : (
                      <>
                        <li className="breadcrumb-item">
                          <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                      </>
                    )}
                    {/* end::Item */}
                  </React.Fragment>
                );
              })}
            </ul>
            {/* end::Breadcrumb */}
          </div>
          {/* end::Page title */}

          <div>
            {expenseBtn}
            {serviceRequestBtn}
            {filterBtn}
          </div>
        </div>
        {/* end::Page Title & Breadcrumbs container */}
      </nav>
      {/* end::Page Title & Breadcrumbs */}
    </>
  );
};
