import React from "react";
import { Link } from "react-router-dom";



export const Footer: React.FC = () => {
  return (
    <>
      {/* begin::Footer */}
      <footer className="footer py-4 d-flex flex-lg-column padding-top-30" id="footer">
        {/* begin::Container */}
        <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
          {/* begin::Copyright */}
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-semibold me-1">Education Development Center (EDC)</span>
                          
            
          </div>
          {/* end::Copyright */}
          {/* begin::Menu */}
          <ul className="menu menu-gray-700 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <Link to="/dashboard" className="menu-link px-2">
                Dashboard
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/servicerequests" className="menu-link px-2">
                Service Requests
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/activities" className="menu-link px-2">
                T/TA Activities
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/staff" className="menu-link px-2">
                Staff
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/reports" className="menu-link px-2">
                Reports
              </Link>
            </li>
          </ul>
          {/* end::Menu */}
        </div>
        {/* end::Container */}
      </footer>
      {/* end::Footer */}
    </>
  );
};
