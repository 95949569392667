import React from "react";
import { DataGrid, GridSortModel, GridCsvExportOptions, GridColDef, GridToolbarContainer, gridClasses, GridToolbarExport } from "@mui/x-data-grid";

interface CustomDataGridProps {
  rows: any[];
  columns: GridColDef[];
  noRowsLabel?: string;
  fileName?: string;

  sortModel?: GridSortModel | undefined;
}

const ExportDataGrid: React.FC<CustomDataGridProps> = ({ rows, columns, noRowsLabel, fileName, sortModel }) => {
  const customCsvOptions: GridCsvExportOptions = {
    allColumns: true,
    fileName: fileName != null ? fileName : undefined,
  };

  const CustomExportToolbar = (props: any) => (
    <div>
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={customCsvOptions} printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    </div>
  );

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        density="comfortable"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          [`& .${gridClasses.columnHeader}`]: {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#222222",
          },
          [`& .${gridClasses.row}`]: {
            minHeight: "4rem !important",
          },
          [`& .${gridClasses.row}:hover`]: {
            bgcolor: "#fff",
          },
          [`& .${gridClasses.cell}`]: {
            fontSize: "1rem",
            fontWeight: 500,
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiTablePagination-selectLabel": {
            marginTop: "1rem",
          },
          "& .MuiTablePagination-displayedRows": {
            marginTop: "1rem",
          },
          boxShadow: 0,
          border: 0,
        }}
        components={{ Toolbar: CustomExportToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        rows={rows}
        columns={columns}
        localeText={{
          noRowsLabel: noRowsLabel ? noRowsLabel : "No rows",
          noResultsOverlayLabel: "No results found.",
        }}
        initialState={{
          pagination: {
            pageSize: 25,
          },
          sorting: {
            sortModel: sortModel,
          },
        }}
        rowsPerPageOptions={[]}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
      />
    </div>
  );
};

export default ExportDataGrid;