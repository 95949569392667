import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSColumnChart } from "components/charts/HSColumnChart";

type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityListChart: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { getLookupDescription, getLookupDescriptions } = useContext(LookupsContext);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {}, [dta]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <Link to={`/activities/read/${encodeURIComponent(encryptData(params.id))}`} className="text-gray-700 text-hover-primary mb-1">
            TA-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "TA-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <div>{params.row.title}</div>;
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",

      renderCell: function (params: any) {
        return <div>{params.row.description}</div>;
      },
    },
    {
      field: "activityStartDate",
      headerName: "DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.activityStartDate ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY") : "") +
              (params.row.activityEndDate ? " - " : "") +
              (params.row.activityEndDate ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY") : "")}
          </>
        );
      },
      valueGetter(params) {
        return (
          (params.row.activityStartDate ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY") : "") +
          (params.row.activityEndDate ? " - " : "") +
          (params.row.activityEndDate ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY") : "")
        );
      },
    },
    {
      field: "serviceRequest_Lookup_ServiceTopics",
      headerName: "TOPIC",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return <>{getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ")}</>;
      },
      valueGetter(params) {
        return getLookupDescriptions(params.row.serviceRequest_Lookup_ServiceTopics).join(" | ");
      },
    },
    {
      field: "taMethods_VirtualCode",
      headerName: "TA METHOD",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.taMethods_VirtualCode ? getLookupDescription(params.row.taMethods_VirtualCode) : "") +
              (params.row.taMethods_FaceToFaceCode ? getLookupDescription(params.row.taMethods_FaceToFaceCode) : "")}
          </>
        );
      },
      valueGetter(params) {
        return (
          (params.row.taMethods_VirtualCode ? getLookupDescription(params.row.taMethods_VirtualCode) : "") +
          (params.row.taMethods_FaceToFaceCode ? getLookupDescription(params.row.taMethods_FaceToFaceCode) : "")
        );
      },
    },
    {
      field: "approvalWorkflowState",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return <ApprovalBadge status={params.row.approvalWorkflowState?.currentStatus} />;
      },
      valueGetter(params) {
        return params.row.approvalWorkflowState?.currentStatus;
      },
    },
  ];

  if (isLoading === true) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return <CustomDataGrid rows={dta} columns={columns} pageSize={25} noRowsLabel={"No Activity Rows"} />;
};
