import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import qs from "qs";

//Local Components
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { decryptData, encryptData } from "utils/crypto";
import { getParamAsString, getParamAsStringArray } from "utils/queryStrings";
import { ActivityCreateUpdate } from "components";

export const ActivityCreatePage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);

  const params = useParams();
  const serviceRequestId: number = Number(decryptData(decodeURIComponent(params.id ?? "")));

  const location = useLocation();
  const { title, topics } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const serviceRequestTitle = getParamAsString(title);
  const serviceRequestTopics = getParamAsStringArray(topics);

  useEffect(() => {
    setBreadCrumbsTitle("T/TA Activity");
    setBreads([
      {
        label: serviceRequestTitle,
        path: `/servicerequests/read/${encodeURIComponent(encryptData(serviceRequestId))}`,
      },
      { label: "New T/TA Activity", path: "" },
    ]);
  }, [serviceRequestId, serviceRequestTitle]);

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ActivityCreateUpdate serviceRequestId={serviceRequestId} serviceRequestTopics={serviceRequestTopics} />
        </div>
      </main>
    </>
  );
};
