import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
//Local Components
import { LookupsContext } from "contexts/lookupsContext";
import { ChartData } from "types/chartData";

import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSHBarChart} from "components/charts/HSHBarChart";


type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const EvaluationQualityInfo: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<ChartData[]>([]);
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const {   getLookupDescription } = useContext(LookupsContext);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    
    const qualities = countQualities(dta);
setChartData(qualities);
  }, [dta]);

  // const getDashboardChart = async (reportCode: string) => {
  //   const response = await axiosInstance.get(`${API_URL}/reports/chart`, {
  //     params: {
  //       reportCode: reportCode,
  //     },
  //   });

  function countQualities(evaluations: BaseRecord[]): ChartData[]{
    //console.log("countDistinctStatuses: data", objects);
    const qualityCounts: { [name: string]: number } = {};

    
    


    // Count the occurrences of each status
    if (evaluations != null)
    {


   
   
      evaluations.forEach((el, index) => {

        if (el.qualtityInfo != null)
        {
      if (qualityCounts[el.qualtityInfo] != null)
      {
        qualityCounts[el.qualtityInfo]++;
      }
      else
      {
        qualityCounts[el.qualtityInfo] = 1;
      }
    }

    
  });

  
}



let result: ChartData[] = [];

for (const sts in qualityCounts) {

result.push( { desc: sts, code: sts, val: qualityCounts[sts]});
  
       
}




return result;
  } 

     
     
  
  
  
  return <HSHBarChart reportCode="EVALUATIONQUALITYINFO" chartData={chartData} isLoading={isLoading} />

};