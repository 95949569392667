import { BaseRecord } from "@refinedev/core";
import { RecordStatus } from "types/enums";
import { UserPermissions } from "types/userPermissions";




export function isActivityApproved(activity: BaseRecord | undefined): boolean {
  return activity?.approvalWorkflowState?.currentStatus && (activity?.approvalWorkflowState?.currentStatus === RecordStatus.Approved || activity?.approvalWorkflowState?.currentStatus === RecordStatus.Completed);
}







export function canEditAtCurrentApprovalStep(ste: RecordStatus, usp: UserPermissions): boolean
{
  let tmpCanEdit: boolean = true;

  if (usp.canUpdate === false) {
    tmpCanEdit = false;
  }
  if (usp.canEditAfterComplete === false && ste === RecordStatus.Completed.toString()) {
    tmpCanEdit = false;
  }
  if (usp.canEditActivityAfterSubmitToCore === false && ste === RecordStatus.SubmittedToCore.toString()) {
    tmpCanEdit = false;
  }

  if (usp.canEditActivityAfterSubmitToFiscal === false && ste === RecordStatus.SubmittedToFiscal.toString()) {
    tmpCanEdit = false;
  }
  if (usp.canEditActivityAfterSubmitToSamsha === false && ste === RecordStatus.SubmittedToSAMHSA.toString()) {
    tmpCanEdit = false;
  }
return tmpCanEdit;
}








export function canEditServiceRequestAtStep(ste: RecordStatus, usp: UserPermissions): boolean
{
  let tmpCanEdit: boolean = true;

  
  
  if (usp.canUpdate === false) {
    tmpCanEdit = false;
  }

  if (usp.canEditServiceRequestAfterSubmitToCore === false && ste === RecordStatus.SubmittedToCore.toString()) {
    tmpCanEdit = false;
  }

  if (usp.canEditServiceRequestAfterComplete === false && ste === RecordStatus.Completed.toString()) {
    tmpCanEdit = false;
  }

  
  
  if (usp.canEditServiceRequestAfterApproved === false && ste === RecordStatus.Approved.toString()) {
    tmpCanEdit = false;
  }





  if (usp.canEditServiceRequestEvaluationPending === false && ste === RecordStatus.EvaluationPending.toString()) {

    tmpCanEdit = false;
  }








  if (usp.canEditServiceRequestEvaluationSent === false && ste === RecordStatus.EvaluationSent.toString()) {

    tmpCanEdit = false;
  }

  
  
  
  return tmpCanEdit;

}