import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { idID } from "@mui/material/locale";

type HSChartParams = {
  reportCode: string;
  chartData: { name: string; y: number }[];
  isLoading?: boolean;
};


export const HSPieChart: React.FC<HSChartParams> = ({reportCode, chartData, isLoading}) => {
  const { getLookupDescription, getLookupDescriptions } =
    useContext(LookupsContext);
  const { setBreadcrumbs } = useContext(BreadcrumbContext);


  const [ chartResult, setChartResult] = useState(null);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  
  useEffect(() => {
    
  }, []);

 // const getDashboardChart = async (reportCode: string) => {
 //   const response = await axiosInstance.get(`${API_URL}/reports/chart`, {
 //     params: {
 //       reportCode: reportCode,
 //     },
 //   });
 
 // };

  const options = {
    chart: {
      type: "pie",
      height: 390,
      spacing: [0, 0, 0, 0],
            margin: [-90, 0, 50, 0]
              },
    title: {
      text: null,
    },

    
    
    
    plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<b>{point.y}</b>',
              distance: -50,
              style: {
                textShadow: false,
                fontSize: 14,
                color: '#ffffff',
                textOutline: false
              }
          }
      }
  },
    tooltip: {
      pointFormat: "<b>{point.y} ({point.percentage:.1f}%)</b>",
      shared: true,
      style: {
        fontSize: 14
      }
    },
    series: [
      {
        label: {
          enabled: false
        },
        data: chartData,
        showInLegend: true,
        colors: [
          '#4c5f90',
          '#2b6d9e',
          '#007aa6',
          '#0086a5',
          '#00929d',
          '#009c8e',
          '#01a47b'
        ]
      },
    ],
    


    legend: {
      
      enabled: true
    },
    credits: {
      enabled: false,
    },
  
  };


  if (isLoading === true)
  {
    return (
      <Box sx={{ display: "flex" }}>
              <CircularProgress color="primary" />
            </Box>
      
      );
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
