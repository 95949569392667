import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BaseRecord, useOne, useUpdate } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { decryptData } from "utils/crypto";
import { StaffUpdate } from "components";

export const StaffEditPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const params = useParams();
  const userId: string = decryptData(decodeURIComponent(params.userId ?? ""));

  const [id, setId] = useState(userId);
  const { data, isLoading, isError } = useOne({
    resource: "users",
    id,
  });
  const userData: BaseRecord = data?.data ?? [];

  const updateMethod = useUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData !== null && userData !== undefined && isLoading === false) {
      setBreadCrumbsTitle("Staff");
      setBreads([
        { label: "Staff", path: "/staff" },
        { label: userData.firstName + " " + userData.lastName, path: "" },
      ]);
    }
  }, [userData]);

  if (isLoading) {
    return (
      <>
        <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
        <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="flex-row-fluid" id="content">
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="primary" />
            </Box>
          </div>
        </main>
      </>
    );
  }

  if (isError) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div>Error: Could not load Staff data.</div>
        </div>
      </main>
    );
  }

  const onSubmit = async (data: BaseRecord) => {
    await updateMethod.mutateAsync({
      resource: "users",
      values: data,
      id: userId,
    });
    setTimeout(() => {
      navigate("/staff");
    }, 100); //TODO: this is a hack to allow the mutation to complete before navigating away
  };

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <StaffUpdate userData={userData} onSubmit={onSubmit} />
        </div>
      </main>
      {/* begin::Post */}
    </>
  );
};
