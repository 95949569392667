import React, { useState, useEffect } from "react";
import { useMany, HttpError } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { StaffList } from "components";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";

export const StaffListPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const [ids, setIds] = useState([]);
  const { data, isLoading, isError } = useMany<any, HttpError>({
    resource: "users/staff",
    ids,
  });
  const staff = data?.data ?? [];

  useEffect(() => {
    setBreadCrumbsTitle("Staff");
    setBreads([{ label: "Staff", path: "/staff" }]);
  }, []);

  if (isLoading) {
    return (
      <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="primary" />
          </Box>
        </div>
      </main>
      </>
    );
  }

  if (isError) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div>Error: Could not load Staff data.</div>;
        </div>
      </main>
    );
  }

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <StaffList data={staff} />
        </div>
      </main>
    </>
  );
};
