import React, { useState, useContext, useEffect, ChangeEvent } from "react";
import { Control, FieldValues, UseFieldArrayRemove, UseFormGetValues, UseFormRegister, UseFormSetValue, useFieldArray } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { GranteeSelectionOptionType } from "types/enums";
import { GranteeSelectionContext } from "contexts/granteeSelectionContext";

type GranteeSelectProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
  formFieldName: string;
  index: number;
  remove?: UseFieldArrayRemove;
};

export const GranteeSelect: React.FC<GranteeSelectProps> = ({ register, control, setValue, getValues, index, remove }) => {
  const { regions, granteeTypes, grantees, grantPrograms, getGranteesByRegionAndType } = useContext(GranteeSelectionContext);

  const [regionSelection, setRegionSelection] = useState<string>(getValues(`granteeSelections[${index}].regionCode`) ?? GranteeSelectionOptionType.AllRegions);
  const [granteeTypeSelection, setGranteeTypeSelection] = useState<string>(getValues(`granteeSelections[${index}].granteeTypeCode`) ?? GranteeSelectionOptionType.AllGranteeTypes);
  const [granteeOptions, setGranteeOptions] = useState(grantees);
  const [granteeSelection, setGranteeSelection] = useState<string>(getValues(`granteeSelections[${index}].granteeCode`) ?? GranteeSelectionOptionType.AllGrantees);

  const { fields } = useFieldArray({
    control,
    name: `granteeSelections.${index}`, // Name of the nested field array in the form data
  });

  const handleRegionChange = (event: ChangeEvent<HTMLSelectElement>) => {





    

    setRegionSelection(event.target.value);

    

    if (getValues(`granteeSelections[0].grantProgramCode`).trim() == "") {
      
      
      
      setValue(`granteeSelections[0].grantProgramCode`, GranteeSelectionOptionType.AllGrantPrograms);
    }
    if (getValues(`granteeSelections[0].granteeTypeCode`).trim() == "")
    {
      console.log("here50", getValues(`granteeSelections[0].granteeTypeCode`));
      setValue(`granteeSelections[0].granteeTypeCode`, GranteeSelectionOptionType.AllGranteeTypes);
    }
    

  };

  const handleGranteeTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setGranteeTypeSelection(event.target.value);
  };

  const handleGranteeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setGranteeSelection(event.target.value);
  };

  useEffect(() => {
    
    setGranteeOptions(getGranteesByRegionAndType(regionSelection, granteeTypeSelection));





if (regionSelection === GranteeSelectionOptionType.AllRegions || granteeTypeSelection === GranteeSelectionOptionType.AllGranteeTypes) {
  setValue(`granteeSelections[${index}].granteeCode`, GranteeSelectionOptionType.AllGrantees);
}
  }, [regionSelection, granteeTypeSelection]);

  return (
    <>
      {/* begin::Inputs */}
      <div className={`col-sm-${remove ? "2" : "3"}`} key={`regionArea${index}`}>
        <label htmlFor={`region${index}`} key={`regionLabel${index}`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
          Region
        </label>

        <select
          id={`region${index}`}
          {...register(`granteeSelections[${index}].regionCode` as const, {
            required: false,
          })}
          className="form-select"
          key={`regionSelect${index}`}
          onChange={handleRegionChange}
        >
          {regions.map((region, index) => (
            <option key={`${region.value}${index}`} value={region.value}>
              {region.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-sm-3" key={`granteeTypeArea${index}`}>
        <label htmlFor={`granteeType${index}`} key={`granteeTypeLabel${index}`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
          Grantee Type
        </label>

        <select
          id={`granteeType${index}`}
          {...register(`granteeSelections[${index}].granteeTypeCode` as const, {
            required: false,
          })}
          className="form-select"
          key={`granteeTypeSelect${index}`}
          onChange={handleGranteeTypeChange}
        >
          {granteeTypes.map((granteeType, index) => (
            <option key={granteeType.value} value={granteeType.value}>
              {granteeType.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-sm-3" key={`granteeArea${index}`}>
        <label htmlFor={`grantee${index}`} key={`granteeLabel${index}`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
          Grantee
        </label>

        <select
          id={`grantee${index}`}
          {...register(`granteeSelections[${index}].granteeCode` as const, {
            required: false,
          })}
          className="form-select"
          key={`granteeSelect${index}`}
          onChange={handleGranteeChange}
        >
          {granteeOptions.map((grantee, index) => (
            <option key={grantee.value} value={grantee.value}>
              {grantee.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-sm-3" key={`grantProgramArea${index}`}>
        <label htmlFor={`grantProgram${index}`} key={`grantProgramLabel${index}`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
          Grant Program
        </label>

        <select
          id={`grantProgram${index}`}
          {...register(`granteeSelections[${index}].grantProgramCode` as const, {
            required: false,
          })}
          className="form-select"
          key={`grantProgramSelect${index}`}
        >
          {grantPrograms.map((grantProgram, index) => (
            <option key={grantProgram.value} value={grantProgram.value}>
              {/* display-only hack for 'all' */}
              {grantProgram.value.startsWith("All") ? "All Grant Programs" : grantProgram.value}
            </option>
          ))}
        </select>
      </div>

      {remove && (
        <div className={`col-sm-1 padding-left-30 ${index > 0 ? "padding-top-10" : "padding-top-35"}`}>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              remove(index);
            }}
          >
            <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove"></i>
          </a>
        </div>
      )}

      {/* end::Input */}
    </>
  );
};

type GranteeSelectionsProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
  formFieldName: string;
};

export const GranteeSelections: React.FC<GranteeSelectionsProps> = ({ register, control, setValue, getValues, formFieldName }) => {
  const defaultGranteeSelection = { regionCode: GranteeSelectionOptionType.AllRegions, granteeTypeCode: GranteeSelectionOptionType.AllGranteeTypes, granteeCode: GranteeSelectionOptionType.AllGrantees, grantProgramCode: GranteeSelectionOptionType.AllGrantPrograms };

  const {
    fields: granteeSelectionFields,
    append: appendGranteeSelection,
    remove: removeGranteeSelection,
  } = useFieldArray({
    control,
    name: "granteeSelections",
  });

  return (
    <div className="row g-5">
      {(!granteeSelectionFields || granteeSelectionFields?.length === 0) && (
        <div className="col-sm-12">
          <span className="h5">Click the 'Add' button below to Invite Grantees</span>
        </div>
      )}

      {granteeSelectionFields?.map((granteeSelection, index) => (
        <GranteeSelect key={granteeSelection.id} register={register} control={control} setValue={setValue} getValues={getValues} formFieldName={formFieldName} index={index} remove={removeGranteeSelection} />
      ))}

      <div className="col-sm-12">
        <button type="button" onClick={() => appendGranteeSelection(defaultGranteeSelection)} className="btn btn-sm btn-dark">
          {!granteeSelectionFields || granteeSelectionFields?.length === 0 ? "Add" : "Add More"}
        </button>
      </div>
    </div>
  );
};
