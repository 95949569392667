import React, { useContext } from "react";
import { UseFormRegister } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { LookupsContext } from "contexts/lookupsContext";

type LookupCheckboxesProps = {
  register: UseFormRegister<BaseRecord>;
  lookupType: string;
  formFieldName: string;
  onFieldChanged?: (val: string, isChecked: boolean) => void;
};

export const LookupCheckboxes: React.FC<LookupCheckboxesProps> = ({ register, lookupType, formFieldName, onFieldChanged}) => {
  
  
  const { lookups } = useContext(LookupsContext);

  const handleFieldChanged = (ev:  any) => {

    if (onFieldChanged != null)
    {
       onFieldChanged(ev.target.value, ev.target.checked);
    }
  };
  
  return (
    <>
      {lookups
        .filter((lookup) => {
          return lookup.lookupType === lookupType;
        })
        .map((lookup, index) => (
          <React.Fragment key={index}>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value={lookup.lookupCode}
                id={lookup.lookupCode}
                key={index}
                {...register(formFieldName, {
                  required: false,
                  value: null,
                })}
                onChange={handleFieldChanged}
              />
              <label className="form-check-label" htmlFor={lookup.lookupCode}>
                {lookup.lookupDescription}
              </label>
            </div>
          </React.Fragment>
        ))}
    </>
  );
};
