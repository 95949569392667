import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSPieChart } from "components/charts/HSPieChart";

type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivitySPFFocusChart: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { getLookupDescription } = useContext(LookupsContext);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    const distinctCounts = countDistinctSPF(dta);

    let tmpChartData: { name: string; y: number }[] = [];
    if (distinctCounts != null) {
      if (distinctCounts != null) {
        for (var i = 0; i < distinctCounts.length; i++) {
          tmpChartData.push({
            name: distinctCounts[i].desc,
            y: distinctCounts[i].val,
          });
        }
      }

      setChartData(tmpChartData);
    }
  }, [dta]);


  function countDistinctSPF(activities: BaseRecord[]): ChartData[] {
    //console.log("countDistinctStatuses: data", objects);
    const spfCounts: { [name: string]: number } = {};

    // Count the occurrences of each status
    for (const att of activities) {
      if (att.focusArea != null) {
        for (const focusAreaCode of att.focusArea.lookup_FocusAreas) {
          if (focusAreaCode != null && focusAreaCode != "") {
            if (spfCounts[focusAreaCode] != null) {
              spfCounts[focusAreaCode]++;
            } else {
              spfCounts[focusAreaCode] = 1;
            }
          }
        }
      }
    }

    // Convert the counts to an array of { name, count } objects
    const distinctCounts: ChartData[] = [];
    for (const fcc in spfCounts) {
      let taFocusCode: string = filterForm?._formValues?.focusArea;

      if (taFocusCode != null && taFocusCode !== "") {
        if (taFocusCode === fcc) {
          distinctCounts.push({ desc: getLookupDescription(fcc), val: spfCounts[fcc], code: fcc });
        }
      } else {
        distinctCounts.push({ desc: getLookupDescription(fcc), val: spfCounts[fcc], code: fcc });
      }
    }



    if (distinctCounts != null)
    {




      distinctCounts.sort((y, z) => y.desc.localeCompare(z.desc));
    }

    
    return distinctCounts;
  }

  return <HSPieChart reportCode="ACTIVITYMETHODSFTOF" chartData={chartData} isLoading={isLoading} />;
};
