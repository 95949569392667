import React, { createContext, useState, ReactNode } from "react";
import { IBreadcrumb } from "interfaces";
import { Breadcrumbs } from "components/breadcrumbs";

interface BreadcrumbContextProps {
  breadcrumbsTitle: string;
  setBreadcrumbsTitle: React.Dispatch<React.SetStateAction<string>>;
  breadcrumbs: IBreadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<IBreadcrumb[]>>;
}

export const BreadcrumbContext = createContext<BreadcrumbContextProps>({
  breadcrumbsTitle: "",
  setBreadcrumbsTitle: () => {},
  breadcrumbs: [],
  setBreadcrumbs: () => {},
});

type BreadcrumbProviderProps = {
  children?: ReactNode;
};

const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [breadcrumbsTitle, setBreadcrumbsTitle] = useState<string>("");

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbsTitle, setBreadcrumbsTitle, breadcrumbs, setBreadcrumbs }}>
      <Breadcrumbs breadcrumbsTitle={breadcrumbsTitle} breadcrumbs={breadcrumbs} />
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;
