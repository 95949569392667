import React, { useState, useEffect, useContext } from "react";
import { BaseRecord } from "@refinedev/core";

//Material Design UI + dependencies
import { EvaluationOppurtunity } from "components/charts/EvaluationOppurtunity";
import { EvaluationSensitivity } from "components/charts/EvaluationSensitivity";
import dayjs from "dayjs";

//Local Components
import { EvaluationFormat } from "components/charts/EvaluationFormat";
import { EvaluationMaterials } from "components/charts/EvaluationMaterials";

import { LookupsContext } from "contexts/lookupsContext";
import { EvaluationOverallChart } from "components/charts/EvaluationOverallChart";
import { EvaluationTraining } from "components/charts/EvaluationTraining";
import { EvaluationContentRelevance } from "components/charts/EvaluationContentRelevance";

import { EvaluationQualityInfo } from "components/charts/EvaluationQualityInfo";
import logo from "assets/media/logos/sptac-logo.png";
import { EvaluationTiming } from "components/charts/EvaluationTiming";
import { EvaluationKnowledge } from "components/charts/EvaluationKnowledge";

import { EvaluationLikelyInfo } from "components/charts/EvaluationLikelyInfo";
import { EvaluationRespect } from "components/charts/EvaluationRespect";

type EvaluationsReportProps = {
  data: BaseRecord[];
};

/* type AttendeesRow = {
  activityId: any,
  title: string,
  startDate: string,
  endDate: string,
  taMethodsVirtual: string,
  taMethodsFaceToFace: string,
  samhsaPriorities: string,
  region: string,
  granteeType: string,
  grantee: string,
  grantProgram: string,
  numOfAttendees: string
} */

export const EvaluationsReport: React.FC<EvaluationsReportProps> = ({ data }) => {
  const [activityData, setActivityData] = useState<BaseRecord>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getLookupDescriptions } = useContext(LookupsContext);

  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    setActivityData(data);
  }, [data]);

  const handlePrintReport = () => {
    window.print();
  };

  return (
   
     
     
     
     
     <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-0">
          <div className="row justify-content-center pt-8 px-8 pt-md-27 px-md-0">
            <div className="col-md-10">
              <div className="page-header d-none d-print-block">
                <div className="pt-10 pb-10 pb-md-20 ">
                  <img alt="Strategic Prevention Technical Assistance Center Logo" src={logo} className="h-50px h-lg-70px me-2 me-lg-9" />
                </div>
              </div>

              <div className="page-footer d-none d-print-block ">
                <div className="d-flex justify-content-between mb-3">
                  <div className="p-2 ">{activityData?.activityTitle}</div>

                  <div className="p-2 ">{activityData?.managingStaffName}</div>
                </div>
              </div>

              <table role="presentation">
                <thead>
                  <tr>
                    <td>
                      <div className="page-header-space d-none d-print-block mb-10"></div>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div className="page">
                        <div className="pb-10  d-print-none ">
                          <img alt="Strategic Prevention Technical Assistance Center Logo" src={logo} className="h-50px h-lg-70px me-2 me-lg-9" />
                        </div>

                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <h1>Evaluation Results</h1>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <p className="fs-2 mb-0">{activityData?.activityTitle}</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex flex-wrap justify-content-center fw-semibold fs-6 mb-4 pe-2">
                              <span className="font-weight-bold text-gray-700 me-5 mb-2">
                                <i aria-hidden="true" className="fa-solid fa-calendar-days text-gray-700 icon-nm mr-2 me-1" title="Activity Date"></i>
                                <span className="sr-only">Activity Date:</span>
                                <span>{activityData?.activityStartDate != null ? dayjs(activityData?.activityStartDate).format("MM/DD/YYYY") : ""}</span>
                              </span>

                              <span className="font-weight-bold text-gray-700 me-5 mb-2">
                                <i aria-hidden="true" className="fas fa-map-marker-alt text-gray-700 icon-nm mr-2 me-1" title="T/TA Method"></i>
                                <span className="sr-only">T/TA Method:</span>
                                <span>
                                  {getLookupDescriptions(activityData?.taMethodsFaceToFace)}&nbsp;
                                  {getLookupDescriptions(activityData?.taMethodsVirtual)}
                                </span>
                              </span>

                              <span className="font-weight-bold text-gray-700 me-5 mb-2">
                                <i aria-hidden="true" className="fas fa-globe text-gray-700 icon-nm mr-2 me-1" title="T/TA Activity Category"></i>
                                <span className="sr-only">T/TA Activity Category:</span>
                                <span>{getLookupDescriptions(activityData?.taCategories)}</span>
                              </span>

                              <span className="font-weight-bold text-gray-700 me-5 mb-2">
                                <i aria-hidden="true" className="fa-solid fa-user text-gray-700 icon-nm mr-2 me-1" title="Staff Planning & Delivering the Activity"></i>
                                <span className="sr-only">Staff Planning & Delivering the Activity:</span>
                                <span>{activityData?.managingStaffName}</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <p className="lead">{activityData?.activityDescription}</p>
                          </div>
                        </div>

                        <div className="row mt-7 mb-3">
                          <div className="col-md-12">
                            <h2 className="fs-2">Session Satisfaction</h2>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Overall Session Satisfaction</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses stating they were satisfied with the overall event/training.</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationOverallChart dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Quality of the information</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses rating the quality of the information presented.</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationQualityInfo dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Relevance of the content to my work</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses stating the content presented was relevant to their work</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationContentRelevance dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>
                      </div>

                      <div className="page">
                        <div className="d-none d-print-block pb-10"></div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Organization of the event/training</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses stating the organization of the event/training.</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationTraining dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Handouts or materials</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses rating the satisfaction of the handouts or materials.</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationMaterials dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Format</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses rating the satisfaction of the event/training.</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationFormat dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Sensitivity of the trainer(s) to the participants</div>
                            <p className="font-size-h6 mb-1">
                              The below results show the % of participant responses rating the satisfaction of the sensitivity of the trainer(s) to the participants.
                            </p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationSensitivity dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Opportunity for questions/discussion</div>
                            <p className="font-size-h6 mb-1">
                              The below results show the % of participant responses rating the satisfaction of the opportunity for questions and discussion at the event/training
                              session.
                            </p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationOppurtunity dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Timing</div>
                            <p className="font-size-h6 mb-1">The below results show the % of participant responses rating the satisfaction of the timing of the event/training.</p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationTiming dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-solid my-7 d-print-none"></div>

                      <div className="page">
                        <div className="row mt-7 mb-3">
                          <div className="col-md-12">
                            <h2 className="fs-2">Knowledge Gain & Additional Information</h2>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Knowledge Gain</div>
                            <p className="font-size-h6 mb-1">
                              The below results show the % of participant responses stating how much more knowledgeable they are about the event/training's content after
                              participating.
                            </p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationKnowledge dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Knowledge Use</div>
                            <p className="font-size-h6 mb-1">
                              The below results show the % of participant responses stating how likely they are to use the information or ideas that were received in the
                              event/training.
                            </p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationLikelyInfo dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Respect</div>
                            <p className="font-size-h6 mb-1">
                              The below results show the % of participant responses stating the event/training was provided in a manner that is respectful of individual beliefs,
                              language, perspectives, and needs.
                            </p>
                          </div>

                          <div className="col-md-12">
                            <EvaluationRespect dta={activityData?.evals} isLoading={isLoading} />
                          </div>
                        </div>

                        <div className="separator separator-solid my-7 d-print-none"></div>

                        <div className="row mt-7 mb-3">
                          <div className="col-md-12">
                            <h2 className="fs-2">Participant Comments</h2>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="h5 mb-1">Additional Comments or Suggestions</div>
                            <p className="font-size-h6 mb-1">Responses to the question: Please write in any additional comments or suggestions that you may have. </p>
                          </div>

                          <div className="col-md-12">
                            <ul>
                              {activityData?.evals?.map((el: any, index: any) => {
                                return <li>{el.additionalComments}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tfoot style={{ border: "0px" }}>
                  <tr>
                    <td>
                      <div className="page-footer-space"></div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div className="row justify-content-center pb-28">
            <div className="col-md-10">
              <div className="d-flex font-size-sm flex-wrap">
                <button type="button" className="btn btn-primary font-weight-bolder py-4 mr-3 mr-sm-14 my-1 d-print-none" onClick={handlePrintReport}>
                  Print Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    
  );
};
