import React, { useContext, useEffect } from "react";
import { BaseRecord } from "@refinedev/core";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//Local Components
import { LookupCheckboxes, StaffRate } from "components";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

type StaffUpdateProps = {
  userData: BaseRecord;
  onSubmit: (data: BaseRecord) => void;
};

export const StaffUpdate: React.FC<StaffUpdateProps> = ({ userData, onSubmit }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: userData,
    
  });

  useEffect(() => {

  reset(userData);  
  
  }, [userData]);


  const { userPermissions } = useContext(LoggedInUserContext);

  return (
    <form id="service_request_form" onSubmit={handleSubmit(onSubmit)} className="form d-flex flex-column flex-lg-row">
      {/* begin::Main column */}
      <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
        {/* begin::T/TA Activity details */}
        <div className="card card-flush pb-2">
          {/* begin::Card header */}
          <div className="card-header">
            <div className="card-title">
              <h2>User Details</h2>
            </div>
          </div>
          {/* end::Card header */}

          {/* begin::Card body */}
          <div className="card-body">
            {/* begin::T/TA Activity Details */}
            <div className="d-flex flex-column">
              <div className="row mb-5">
                <div className="col">
                  <p className="fs-6 fw-bold mb-2">First Name</p>
                  <p className="list-unstyled text-gray-700 fw-semibold fs-6 p-0 m-0">{userData.firstName}</p>
                </div>

                <div className="col">
                  <p className="fs-6 fw-bold mb-2">Last Name</p>
                  <p className="list-unstyled text-gray-700 fw-semibold fs-6 p-0 m-0">{userData.lastName}</p>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col">
                  <p className="fs-6 fw-bold mb-2">Email Address</p>
                  <p className="list-unstyled text-gray-700 fw-semibold fs-6 p-0 m-0">{userData.email}</p>
                </div>

                <div className="col">
                  <p className="fs-6 fw-bold mb-2">Role</p>
                  <p className="list-unstyled text-gray-700 fw-semibold fs-6 p-0 m-0">{userData.role}</p>
                </div>
              </div>
              <div className="row g-5">
                {/* begin::Inputs */}

                <div className="col-md-6">
                  {/* begin::fieldset */}
                  <fieldset>
                    <legend>Approval Notifications</legend>

                    {/* begin::Input */}
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="core" {...register("isCoreApprover")} />
                      <label className="form-check-label" htmlFor="core">
                        CORE Approver
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="fiscal" {...register("isFiscalApprover")} />
                      <label className="form-check-label" htmlFor="fiscal">
                        Fiscal Approver
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="samhsa" {...register("isSamhsaApprover")} />
                      <label className="form-check-label" htmlFor="samhsa">
                        SAMHSA Approver
                      </label>
                    </div>

                    {/* end::Input */}
                  </fieldset>
                  {/* end::fieldset */}
                </div>

                <div className="col-md-6">
                  {/* begin::fieldset */}
                  <fieldset>
                    <legend>Associated Regions</legend>
                    <LookupCheckboxes register={register} formFieldName="lookup_RegionalTeams" lookupType="RegionalTeams" />
                  </fieldset>
                  {/* end::fieldset */}
                </div>
              </div>
              <div className="row g-5">
                {/* begin::Inputs */}

                <div className="col-md-6">
                  {/* begin::fieldset */}
                  <fieldset>
                    <legend>Consultant?</legend>

                    {/* begin::Input */}
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="isConsultant" {...register("isConsultant")} />
                      <label className="form-check-label" htmlFor="isConsultant">
                        Yes
                      </label>
                    </div>
                   
                    {/* end::Input */}
                  </fieldset>
                  {/* end::fieldset */}
                </div>

                
              </div>
              {/* end::Row */}
            </div>
            {/* end::T/TA Activity Details */}
          </div>
          {/* end::Card body */}
        </div>
        {/* end::T/TA Activity details */}

        {/* begin::Rates */}

        {userPermissions.canViewStaffRates === true && (
        <div className="card card-flush pb-2">
          {/* begin::Card header */}
          <div className="card-header">
            <div className="card-title">
              <h2>Rates</h2>
            </div>
          </div>
          {/* end::Card header */}

          {/* begin::Card body */}
          <div className="card-body">
            <StaffRate register={register} control={control} setValue={setValue} />
          </div>
          {/* end::Card body */}
        </div>
        
        )}
        <div className="d-flex justify-content-start">
          {/* begin::Button */}
          <button type="submit" id="" className="btn btn-primary me-5">
            <span className="indicator-label">Save & Close</span>
          </button>
          {/* end::Button */}

          {/* begin::Button */}
          <Link to="/staff/" className="btn btn-bg-light btn-active-color-gray-900 me-5">
            Cancel
          </Link>
          {/* end::Button */}
        </div>
      </div>
      {/* end::Main column */}
    </form>
  );
};
