import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";

import { HSStatFormat, RecordStatus } from "types/enums";

import { LookupsContext } from "contexts/lookupsContext";

import { Control } from "react-hook-form";

import HighchartsReact from "highcharts-react-official";

import { HSStat } from "components/charts/HSStat";

type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityHoursStat: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [totalHours, setTotalHours] = useState<number>(0.0);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const { getLookupDescription } = useContext(LookupsContext);


  useEffect(() => {
    
    let tmpTotalHours: number = 0.0;
    if (dta != null) {
      for (const acc of dta) {
        //if (
        //  acc?.numStaffHours != null &&
        //  acc?.approvalWorkflowState?.currentStatus !== RecordStatus.Draft &&
        //  acc?.approvalWorkflowState?.currentStatus !== RecordStatus.NotApproved
        //) {
          tmpTotalHours = tmpTotalHours + acc.numStaffHours;
        //}
      }
    }

    setTotalHours(tmpTotalHours);
  }, [dta, isLoading]);



  return <HSStat reportCode="ACTIVITYHOURSSTAT" val={totalHours} isLoading={isLoading} hsFormat={HSStatFormat.HSFormattedInteger} />;

};