import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useOne, BaseRecord } from "@refinedev/core";
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";
import { decryptData } from "utils/crypto";
import { ServiceRequestRead } from "components";

export const ServiceRequestShowPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const params = useParams();
  const serviceRequestId: number = Number(decryptData(decodeURIComponent(params.id ?? "")));

  const [id, setId] = useState(serviceRequestId);
  const { data, isLoading, isError } = useOne({
    resource: "servicerequests",
    id,
  });

  const serviceRequestData: BaseRecord = data?.data ?? [];
  useEffect(() => {
    if (serviceRequestData != null && isLoading === false) {
      setBreadCrumbsTitle("Service Request");
      setBreads([
        { label: "Service Requests", path: "/servicerequests" },
        { label: `${serviceRequestData?.title !== undefined ? serviceRequestData?.title : ""} (SR-${id})`, path: "" },
      ]);
    }
  }, [serviceRequestData]);

  if (isLoading) {
    return (
      <>
        <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
        <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="flex-row-fluid" id="content">
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="primary" />
            </Box>
          </div>
        </main>
      </>
    );
  }

  if (isError) {
    return <div>Error: Could not load Service Request data.</div>;
  }

  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ServiceRequestRead data={serviceRequestData} editUri={`/servicerequests/edit/${encodeURIComponent(params.id ?? "")}`} />
        </div>
      </main>
    </>
  );
};
