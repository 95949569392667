import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ApprovalWorkflowStatus } from "types/enums";

export type ApprovalDialogProps = {
  statusStyle?: string;
  buttonText: string;
  buttonIcon?: React.ReactNode;
  dialogTitle: string;
  dialogContentText: string;
  submitButtonText: string;
  submissionStatus: ApprovalWorkflowStatus;
  redirectToPostSubmit?: string;
  hasValidationErrors?: boolean;
  validationErrors?: { [key: string]: string };
  dialogContentErrorText?: string;
  validationDetailMessage?: string;
  onSubmit: (submissionStatus: ApprovalWorkflowStatus, inputValue: string, redirectTo?: string) => void;
  disableButtons?: boolean;
  buttonSizeClass?: string;
  buttonSpanClassName?: string;
};

export const ApprovalDialog: React.FC<ApprovalDialogProps> = ({
  statusStyle,
  buttonText,
  buttonIcon,
  dialogTitle,
  dialogContentText,
  submitButtonText,
  submissionStatus,
  redirectToPostSubmit,
  hasValidationErrors,
  validationErrors,
  dialogContentErrorText,
  validationDetailMessage,
  onSubmit,
  disableButtons,
  buttonSizeClass,
  buttonSpanClassName
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (disableButtons != null) {
      setIsProcessing(disableButtons);
      setIsSubmitted(disableButtons);
    } else {
      setIsProcessing(false);
      setIsSubmitted(false);
    }
  }, [
    statusStyle,
    buttonText,
    buttonIcon,
    dialogTitle,
    dialogContentText,
    submitButtonText,
    submissionStatus,
    redirectToPostSubmit,
    hasValidationErrors,
    validationErrors,
    dialogContentErrorText,
    validationDetailMessage,
    disableButtons,
  ]);

  const handleClickOpen = () => {
    setIsProcessing(true);
    setOpen(true);
  };

  const handleClose = () => {
    setIsProcessing(false);
    setIsSubmitted(false);
    setInputValue("");
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    onSubmit(submissionStatus, inputValue, redirectToPostSubmit);
    setInputValue("");
    setOpen(false);
  };

  return (
    <>
      <Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={"!"}
        color="error"
        invisible={!hasValidationErrors}
        className={`float-right ${buttonSpanClassName != null ? buttonSpanClassName : ""}`}
      >
        <button onClick={handleClickOpen} className={`btn ${buttonSizeClass != null ? buttonSizeClass : "btn-sm"} me-2 btn-${statusStyle ?? "primary"}`} disabled={isProcessing}>
          <span>
            {buttonText} {buttonIcon && buttonIcon}
          </span>
        </button>
      </Badge>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {dialogTitle}
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="modal-body" hidden={!hasValidationErrors}>
            <p>{dialogContentErrorText}</p>

            <p>{validationDetailMessage != null && validationDetailMessage !== "" ? validationDetailMessage : "Please edit and complete the following fields:"}</p>
            <div className="col-12">
              <ul>
                {validationErrors &&
                  Object.entries(validationErrors).map(([key, value]) => (
                    <li key={key}>
                      <span>{value}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="modal-body" hidden={hasValidationErrors}>
            <p>{dialogContentText}</p>
            <div className="col-12">
              <label htmlFor="need" className="form-label">
                Message (optional)
              </label>
              <textarea className="form-control" rows={3} id="need" name="need" value={inputValue} onChange={handleInputChange} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="modal-footer" hidden={hasValidationErrors}>
            <button type="button" onClick={handleSubmit} className={`btn btn-${statusStyle ?? "primary"} mx-5 my-5`} disabled={isSubmitted}>
              {submitButtonText}
            </button>
            <button type="button" onClick={handleClose} className="btn btn-link mx-5" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
          <div className="modal-footer" hidden={!hasValidationErrors}>
            <button type="button" onClick={handleClose} className="btn btn-link mx-5" data-bs-dismiss="modal">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
