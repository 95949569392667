import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSColumnChart } from "components/charts/HSColumnChart";


type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityPopulationChart: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const {   getLookupDescription } = useContext(LookupsContext);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    const distinctCounts = countDistinctPopulations(dta);

    let tmpChartData: { name: string; y: number }[] = [];
    if (distinctCounts != null) {
      if (distinctCounts != null) {
        for (var i = 0; i < distinctCounts.length; i++) {
          tmpChartData.push({
            name: distinctCounts[i].desc,
            y: distinctCounts[i].val
          });
        }
      }
      
      setChartData(tmpChartData);
    }
  }, [dta]);

  // const getDashboardChart = async (reportCode: string) => {
  //   const response = await axiosInstance.get(`${API_URL}/reports/chart`, {
  //     params: {
  //       reportCode: reportCode,
  //     },
  //   });

  function countDistinctPopulations(activities: BaseRecord[]): ChartData[] {
    //console.log("countDistinctStatuses: data", objects);
    
    
    const populationCounts: { [name: string]: number } = {};

    // Count the occurrences of each status
    for (const att of activities) {
      if (att.lookup_ServiceAudience_Populations != null) {
        for (const sel of att.lookup_ServiceAudience_Populations) {
          if (populationCounts[sel] != null) {
            populationCounts[sel]++;
          } else {
            populationCounts[sel] = 1;
          }
        }
      }
    }

    // Convert the counts to an array of { name, count } objects
    const distinctCounts: ChartData[] = [];
    for (const pop in populationCounts) {
      let population: string = filterForm?._formValues?.population;

      

      if (population != null && population !== "") {
        if (pop === population) {
          distinctCounts.push({ desc: getLookupDescription(pop), val: populationCounts[pop], code: pop });
        }
      } else {
        distinctCounts.push({ desc: getLookupDescription(pop), val: populationCounts[pop], code: pop });
      }
    }
    if (distinctCounts != null)
    {




      distinctCounts.sort((y, z) => y.desc.localeCompare(z.desc));
    }
        
    
    return distinctCounts;
    
  }

  
  
  
  
  
  
  return <HSColumnChart reportCode="ACTIVITYPOPULATIONCOUNTS" chartData={chartData} isLoading={isLoading} />;
};
