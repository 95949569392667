//import CryptoJS from "crypto-js";

//const key = CryptoJS.enc.Utf8.parse(`${process.env.REACT_APP_CRYPTO_KEY}`);
//const iv = CryptoJS.enc.Utf8.parse(`${process.env.REACT_APP_CRYPTO_IV}`);

export const encryptData = (dataToEncrypt: string | number | undefined): string => {
  //const encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), key, {
  //  iv: iv,
  // mode: CryptoJS.mode.CBC,
  //  padding: CryptoJS.pad.Pkcs7,
  //});
  const encrypted = dataToEncrypt;
  return encrypted != null ? encrypted.toString() : "";
};

export const decryptData = (dataToDecrypt: string): string => {
  //const decrypted = CryptoJS.AES.decrypt(dataToDecrypt, key, {
  //  iv: iv,
  //  mode: CryptoJS.mode.CBC,
  //  padding: CryptoJS.pad.Pkcs7,
  //});
  const descrypted = dataToDecrypt;
  // return decrypted.toString(CryptoJS.enc.Utf8).replaceAll('"', "");


  return descrypted;
};
