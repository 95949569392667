import React, { useContext, useState, useEffect } from "react";
import { useMany, HttpError, BaseRecord } from "@refinedev/core";

//Material Design UI + dependencies
import { Box, CircularProgress } from "@mui/material";

//Local Components
import { ActivityList } from "components";
import { Breadcrumbs } from "components/breadcrumbs/index";
import { IBreadcrumb } from "interfaces/index";

export const ActivityListPage: React.FC = () => {
  const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>("");
  const [breads, setBreads] = useState<IBreadcrumb[]>([]);
  const [isFilterChecked, setIsFilterChecked] = useState<boolean>(false);
  const [ids, setIds] = useState([]);
  const { data, isFetching, isError } = useMany<any, HttpError>({
    resource: "activities",
    ids,
  });
  const activities = data?.data ?? [];

  useEffect(() => {
    setBreadCrumbsTitle("T/TA Activities");
    setBreads([{ label: "T/TA Activities", path: "/activities" }]);
    
  }, []);

  const onFilterChange = () => {
    if (isFilterChecked === false) {
      setIsFilterChecked(true);
    } else {
      setIsFilterChecked(false);
    }
  };

  const handleFilterResults = () => {

    setIsFilterChecked(false);
  }





  useEffect(() => {

    console.log("here30", isFetching);
  }, [isFetching]);

  
  if (isFetching === true) {
    return (
      <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} onFilterClicked={onFilterChange} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="primary" />
          </Box>
        </div>
      </main>
      </>
    );
  }

  

  if (isError) {
    return (
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <div>Error: Could not load T/TA Activity data.</div>
        </div>
      </main>
    );
  }
  return (
    <>
      <Breadcrumbs breadcrumbsTitle={breadCrumbsTitle} breadcrumbs={breads} onFilterClicked={onFilterChange} />
      <main id="content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="flex-row-fluid" id="content">
          <ActivityList data={activities} isFilterExpanded={isFilterChecked} onFilterResults={handleFilterResults} />
        </div>
      </main>
    </>
  );
};
