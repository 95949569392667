import React, { useEffect } from "react";
import { UseFormRegister, Control, useFieldArray, Controller } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";

//Material Design UI + dependencies
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

type FiscalYearProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
};

export const FiscalYear: React.FC<FiscalYearProps> = ({ register, control }) => {
  const {
    fields: fiscalYearFields,
    append: appendFiscalYear,
    remove: removeFiscalYear,
  } = useFieldArray({
    control,
    name: "fiscalYears",
  });

  useEffect(() => {
    control?._formValues?.fiscalYears?.length === 0 && appendFiscalYear({});
  }, []);

  return (
    <>
      {fiscalYearFields.map((fiscalYear, index) => (
        <React.Fragment key={index}>
          <div className="col-sm-6" key={fiscalYear.id}>
            <label htmlFor={`fiscalYear_${index}`} className={`form-label ${index > 0 ? "sr-only" : ""}`}>
              Fiscal Year
            </label>

            <div className="input-group">
              {/* <Controller control={control} name={`fiscalYears[${index}].fiscalYear`} render={({ field }) => <input {...field} className="form-control" />} /> */}
              {/* <input
                {...register(`fiscalYears[${index}].fiscalYear` as const, {
                  required: true,
                })}
                className="form-control"
                id="fiscalLabel"
                type="number"
                key={index}
              /> */}
              <Controller
                name={`fiscalYears[${index}].fiscalYear`}
                control={control}
                defaultValue={undefined}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker openTo="year" views={["year"]} onChange={onChange} value={value ? dayjs(value) : undefined} className="form-control"
                    slotProps={{
                      textField: {
                        id: `fiscalYear_${index}`
                      }
                    }}       />
                  </LocalizationProvider>
                )}
              />
            </div>
            {/* {errors?.fiscalYears?.[index] && <span className="text-danger">Fiscal Year is required. Use Remove button if not applicable.</span>} */}
          </div>
          <div className="col-sm-6">
            {/* begin::fieldset */}
            <fieldset>
              <legend className={`${index > 0 ? "mb-5 sr-only" : ""}`}>Quarter</legend>

              <div className="row">
                <div className={`col-sm-12 ${fiscalYearFields.length > 1 ? "mt-2" : ""}`}>
                  {/* begin::Input */}
                  <div className="form-check form-check-inline">
                    <input
                      {...register(`fiscalYears[${index}].q1` as const, {
                        required: false,
                      })}
                      type="checkbox"
                      className="form-check-input"
                      key={index}
                      id={`quarter_1_${index}`}
                    />
                    <label className="form-check-label" htmlFor={`quarter_1_${index}`}>
                      Quarter 1
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      {...register(`fiscalYears[${index}].q2` as const, {
                        required: false,
                      })}
                      type="checkbox"
                      className="form-check-input"
                      key={index}
                      id={`quarter_2_${index}`}
                    />
                    <label className="form-check-label" htmlFor={`quarter_2_${index}`}>
                      Quarter 2
                    </label>
                  </div>
                  {/* end::Input */}

                  {/* begin::Input */}
                  <div className="form-check form-check-inline">
                    <input
                      {...register(`fiscalYears[${index}].q3` as const, {
                        required: false,
                      })}
                      type="checkbox"
                      className="form-check-input"
                      key={index}
                      id={`quarter_3_${index}`}
                    />
                    <label className="form-check-label" htmlFor={`quarter_3_${index}`}>
                      Quarter 3
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      {...register(`fiscalYears[${index}].q4` as const, {
                        required: false,
                      })}
                      type="checkbox"
                      className="form-check-input"
                      key={index}
                      id={`quarter_5_${index}`}
                    />
                    <label className="form-check-label" htmlFor={`quarter_5_${index}`}>
                      Quarter 4
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <a hidden={fiscalYearFields.length === 1} onClick={() => removeFiscalYear(index)} style={{ cursor: "pointer" }}>
                      <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove" style={{ paddingTop: "15px" }}></i>
                    </a>
                  </div>
                  {/* end::Input */}
                </div>

                {/* end::row */}
              </div>

              {/* end::fieldset */}
            </fieldset>
          </div>
        </React.Fragment>
              ))}

      <div className="col-sm-12">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => appendFiscalYear({})}>
          Add More
        </button>
      </div>
    </>
  );
};
