import React, { useRef, useState, useEffect } from "react";
import { DataGrid, GridToolbarQuickFilter, GridFilterForm, GridToolbar, GridColDef, GridToolbarContainer, gridClasses, GridSortModel } from "@mui/x-data-grid";



const CustomToolbar = (props: any) => (
  <div>
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  </div>
);



interface CustomDataGridProps {
  rows: any[];
  columns: GridColDef[];
  pageSize?: number;
  noRowsLabel?: string;
  sortModel?: GridSortModel | undefined;
  }


const CustomDataGrid: React.FC<CustomDataGridProps> = ({ rows, columns, noRowsLabel, sortModel}) => {
  const [searchId, setSearchId] = useState<string>("");
  const myRef = useRef<any>();


  
  useEffect(() => {

  
  
      setSearchId(myRef?.current?.querySelector('[type="search"]')?.id);
  }, [rows]);
  
  return (
    <div style={{ width: "100%" }} ref={myRef}>
      <label className="sr-only" htmlFor={searchId}>Search</label>
      <DataGrid
        autoHeight
        density="comfortable"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          [`& .${gridClasses.columnHeader}`]: {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#222222",
          },
          [`& .${gridClasses.row}`]: {
            minHeight: "4rem !important",
          },
          [`& .${gridClasses.row}:hover`]: {
            bgcolor: "#fff",
          },
          [`& .${gridClasses.cell}`]: {
            fontSize: "1rem",
            fontWeight: 500,
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiTablePagination-selectLabel": {
            marginTop: "1rem",
          },
          "& .MuiTablePagination-displayedRows": {
            marginTop: "1rem",
          },
          boxShadow: 0,
          border: 0,
        }}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        rows={rows}
        columns={columns}
        localeText={{
          noRowsLabel: noRowsLabel ? noRowsLabel : "No rows",
          noResultsOverlayLabel: "No results found.",
        }}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        initialState={{
          pagination: {
            

            pageSize: 25,
          },
          sorting: {

            sortModel: sortModel
          }
        }}
        rowsPerPageOptions={[]} 
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
      />
    </div>
  );
};

export default CustomDataGrid;
