import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { LookupsContext } from "contexts/lookupsContext";
import { ChartData } from "types/chartData";
import { Control } from "react-hook-form";
import HighchartsReact from "highcharts-react-official";
import { HSPieChart } from "components/charts/HSPieChart";

type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityFToFMethodsChart: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { getLookupDescription } = useContext(LookupsContext);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    const distinctCounts = countActivityFToFMethods(dta);

    let tmpChartData: { name: string; y: number }[] = [];
    if (distinctCounts != null) {
      if (distinctCounts != null) {
        for (var i = 0; i < distinctCounts.length; i++) {
          tmpChartData.push({
            name: distinctCounts[i].desc,
            y: distinctCounts[i].val,
          });
        }
      }

      setChartData(tmpChartData);
    }
  }, [dta]);

  // const getDashboardChart = async (reportCode: string) => {
  //   const response = await axiosInstance.get(`${API_URL}/reports/chart`, {
  //     params: {
  //       reportCode: reportCode,
  //     },
  //   });

  function countActivityFToFMethods(activities: BaseRecord[]): ChartData[] {
    //console.log("countDistinctStatuses: data", objects);
    const taMethodCounts: { [name: string]: number } = {};

    // Count the occurrences of each status
    for (const att of activities) {
      if (att.taMethods_FaceToFaceCode != null && att.taMethods_FaceToFaceCode != "") {
        if (taMethodCounts[att.taMethods_FaceToFaceCode] != null) {
          taMethodCounts[att.taMethods_FaceToFaceCode]++;
        } else {
          taMethodCounts[att.taMethods_FaceToFaceCode] = 1;
        }
      }
    }

    // Convert the counts to an array of { name, count } objects
    const distinctCounts: ChartData[] = [];
    for (const met in taMethodCounts) {
      let taMethodCode: string[] = filterForm?._formValues?.lookup_TAMethods_FaceToFace;

      if (taMethodCode != null && taMethodCode.length > 0 && taMethodCode.includes(met)) {
        distinctCounts.push({ desc: getLookupDescription(met), val: taMethodCounts[met], code: met });
      } else {
        distinctCounts.push({ desc: getLookupDescription(met), val: taMethodCounts[met], code: met });
      }
    }

    if (distinctCounts != null) {
      distinctCounts.sort((y, z) => y.desc.localeCompare(z.desc));
    }

    return distinctCounts;
  }

  return <HSPieChart reportCode="ACTIVITYMETHODSFTOF" chartData={chartData} isLoading={isLoading} />;
};
