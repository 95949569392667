import React, { useEffect } from "react";
import { UseFormRegister, Control, useFieldArray, Controller, UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { NumericFormat } from "react-number-format";

type DailyStaffExpenseProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
  staff: BaseRecord[];
  lookups: BaseRecord[];
};

export const DailyStaffExpense: React.FC<DailyStaffExpenseProps> = ({ register, control, setValue, getValues, staff, lookups }) => {
  const defaultDailyExpense = { staffUserId: "", subTypeCode: "", estimatedExpense: 0, estimatedDays: 0, estimatedTotal: 0, actualExpense: 0, actualDays: 0, actualTotal: 0 };

  const {
    fields: dailyExpenseFields,
    append: appendDailyExpense,
    remove: removeDailyExpense,
  } = useFieldArray({
    control,
    name: "dailyExpenses",
  });

  useEffect(() => {
    control?._formValues?.dailyExpenses?.length === 0 && appendDailyExpense(defaultDailyExpense);
  }, []);

  const handleExpenseChange = (field: string, dayField: string, sumField: string, value: string) => {
    let valueConverted = parseFloat(value.replace(/[^0-9.-]/g, ""));
    if (isNaN(valueConverted)) valueConverted = 0;

    if (value === ".") {
      setValue(field, value);
    } else {
      setValue(field, valueConverted);
    }

    const days = getValues(dayField);
    if (valueConverted && Number(valueConverted) && days && Number(days) > 0) {
      setValue(sumField, Number(valueConverted) * Number(days));
    } else {
      setValue(sumField, 0);
    }
  };

  const handleDayChange = (field: string, expenseField: string, sumField: string, value: string) => {
    let valueConverted = parseFloat(value.replace(/[^0-9.-]/g, ""));
    if (isNaN(valueConverted)) valueConverted = 0;

    if (value === ".") {
      setValue(field, value);
    } else {
      setValue(field, valueConverted);
    }

    const expense = getValues(expenseField);
    if (valueConverted && Number(valueConverted) && expense && Number(expense) > 0) {
      setValue(sumField, Number(valueConverted) * Number(expense));
    } else {
      setValue(sumField, 0);
    }
  };

  return (
    <>
      <h3 className="h4 mb-0">Lodging, Rental Space and Per Diem</h3>

      <div className="col-sm-12">
        <div className="table-responsive">
          <table className="table table-stripe">
            <thead className="table-light">
              <tr className="fw-bold mb-2 align-top">
                <th id="staffNameLod" scope="col" className="ps-1" style={{ width: "20%" }}>
                  Staff Member
                </th>
                <th id="staffExpCatLod" scope="col" style={{ width: "20%" }}>
                  Expense Category
                </th>
                <th id="staffEstExpLod" scope="col" style={{ width: "100px" }}>
                  Estimated
                  <br />
                  Expense/Day
                </th>
                <th id="staffNumDaysEst" scope="col" style={{ width: "100px" }}>
                  # of Days
                </th>
                <th id="staffEstExpCalc" scope="col" style={{ width: "100px" }}>
                  Estimated
                  <br />
                  Expenses
                </th>
                <th id="staffActExpLod" scope="col" style={{ width: "100px" }}>
                  Actual
                  <br />
                  Expenses/Day
                </th>
                <th id="staffNumDaysAct" scope="col" style={{ width: "100px" }}>
                  # of Days
                </th>
                <th id="staffActExpCalc" scope="col" style={{ width: "100px" }}>
                  Actual
                  <br />
                  Expenses
                </th>
                <th><span className="sr-only">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              {dailyExpenseFields.map((expense, index) => (
                <>
                  <tr>
                    <td className="td-150">
                      <select key={expense.id} id="staffPlan" {...register(`dailyExpenses[${index}].staffUserId` as const, { required: false })} className="form-select">
                        <option value="">Select...</option>
                        {staff?.map((user, index) => (
                          <option key={index} value={user.userId}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="td-150">
                      <select key={expense.id} id="granteeRecipient" {...register(`dailyExpenses[${index}].subTypeCode`, { required: false })} className="form-select mb-3"
                      aria-labelledby="staffExpCatLod">
                        <option value="">Select...</option>
                        {lookups
                          .filter((lookup) => {
                            return lookup.lookupType === "StaffExpenseDaily";
                          })
                          .map((lookup, index) => (
                            <option key={index} value={lookup.lookupCode}>
                              {lookup.lookupDescription}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`dailyExpenses[${index}].estimatedExpense`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            aria-labelledby="staffEstExpLod"
                            className="form-control"
                            prefix={"$"}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => handleExpenseChange(`dailyExpenses[${index}].estimatedExpense`, `dailyExpenses[${index}].estimatedDays`, `dailyExpenses[${index}].estimatedTotal`, e.value)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`dailyExpenses[${index}].estimatedDays`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            aria-labelledby="staffNumDaysEst"
                            className="form-control"
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              if (floatValue !== undefined && values.value === ".") {
                                floatValue = 0;
                              }
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => handleDayChange(`dailyExpenses[${index}].estimatedDays`, `dailyExpenses[${index}].estimatedExpense`, `dailyExpenses[${index}].estimatedTotal`, e.value)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`dailyExpenses[${index}].estimatedTotal`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat aria-labelledby="staffEstExpCalc" className="form-control-plaintext" prefix={"$"} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} allowNegative={false} defaultValue={0} value={value} disabled={true} />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`dailyExpenses[${index}].actualExpense`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            aria-labelledby="staffActExpLod"
                            className="form-control"
                            prefix={"$"}
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => handleExpenseChange(`dailyExpenses[${index}].actualExpense`, `dailyExpenses[${index}].actualDays`, `dailyExpenses[${index}].actualTotal`, e.value)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`dailyExpenses[${index}].actualDays`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat
                            aria-labelledby="staffNumDaysAct"
                            className="form-control"
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            defaultValue={0}
                            value={value}
                            allowLeadingZeros
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              let { floatValue } = values;
                              //if (floatValue !== undefined && values.value === ".") {
                              //  floatValue = 0;
                              //}
                              return floatValue !== undefined ? floatValue < 1000000 : true;
                            }}
                            onValueChange={(e: any) => handleDayChange(`dailyExpenses[${index}].actualDays`, `dailyExpenses[${index}].actualExpense`, `dailyExpenses[${index}].actualTotal`, e.value)}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        key={expense.id}
                        name={`dailyExpenses[${index}].actualTotal`}
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => (
                          <NumericFormat aria-labelledby="staffActExpCalc" className="form-control-plaintext" prefix={"$"} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} allowNegative={false} defaultValue={0} value={value} disabled={true} />
                        )}
                      />
                    </td>
                    <td style={{ minWidth: "40px", paddingTop: "2%" }}>
                      <a key={expense.id} hidden={getValues(`dailyExpenses`).length === 1} style={{ cursor: "pointer" }} onClick={() => removeDailyExpense(index)}>
                        <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove"></i>
                      </a>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add More */}
      <div className="col-sm-12 mt-0 mb-5">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => appendDailyExpense(defaultDailyExpense)}>
          Add More
        </button>
      </div>
    </>
  );
};
